import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Imago from "../img/Image.module.css";

const RelatedServices = (props) => {
  console.log(props);
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    setId(props.data.serviceDetails.category.service_category_id);
    console.log(token);

    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/product/get_service_posts/",
        {
          service_cat_id: id,
          record_number: "",
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            // "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDetails(res.data.service_posts.slice(0, 6));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, props.data.serviceDetails.category.service_category_id, token]);

  return (
    <div>
      <h2 className="h5 text-uppercase mb-4">Related products</h2>
      <div className="row">
        {/* PRODUCT*/}
        {details &&
          details.map((detail) => {
            return (
              <div
                className="col-lg-2 col-md-3 col-6 p-1"
                key={detail.service_uuid}>
                <div className="product text-center skel-loader">
                  <div className="d-block mb-3 position-relative">
                    <Link
                      className="d-block"
                      to={{
                        pathname: `/ServicesDetails`,
                        state: { data: detail.service_uuid },
                      }}>
                      <img
                        className={Imago.imago}
                        src={detail.attached_image}
                        alt="..."
                      />
                    </Link>
                    <div className="product-overlay"></div>
                  </div>
                  <h6>
                    {" "}
                    <Link
                      className="reset-anchor"
                      to={{
                        pathname: `/ServicesDetails`,
                        state: { data: detail.service_uuid },
                      }}>
                      {detail.service_name}
                    </Link>
                  </h6>
                  <p className="small text-muted"></p>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
};

export default RelatedServices;
