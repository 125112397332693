import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Search from "../AdvancePage/Search";
import Hero from "./Header.module.css";

const Header = () => {
  const [token, setToken] = useState(null);
  const [cat, setCat] = useState([]);
  const [cart, setCart] = useState([]);
  const [catServe, setCatServe] = useState([]);
  const [user, setUser] = useState({
    userDetails: null,
  });

  const endSession = () => {
    // sessionStorage.removeItem('token');

    // Remove all saved data from sessionStorage
    sessionStorage.clear();
  };

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    //   To get the product list
    axios
      .get(
        "https://rosmallonline.com:8000/ecoms-api/setup/list_product_category/"
      )
      .then((res) => {
        console.log(res.data);
        setCat(res.data.product_category);
      })
      .catch((error) => {
        console.log(error);
      });

    //   To get the services list
    axios
      .get(
        "https://rosmallonline.com:8000/ecoms-api/setup/list_service_category/"
      )
      .then((response) => {
        console.log(response.data);
        setCatServe(response.data.service_category);
      })
      .catch((erro) => {
        console.log(erro);
      });

    axios
      .get(
        "https://rosmallonline.com:8000/ecoms-api/shopper/view_shopper_details/",
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setUser((user) => {
          return { ...user, userDetails: res.data.shopper_details };
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get("https://rosmallonline.com:8000/ecoms-api/cart/shopper_cart/", {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCart(response.data.shopper_cart_data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  return (
    <div>
      <header className="header">
        {/* Top Bar */}
        <div className="top_bar">
          <div className="container">
            <div className="row">
              <div className="col d-flex flex-row">
                <div className="top_bar_contact_item">
                  <div>
                    <a href="/">Home</a>
                  </div>
                </div>
                <div className="top_bar_contact_item">
                  <div />
                </div>
                <div className="top_bar_contact_item pl-3">
                  <div />
                </div>
                <div className="top_bar_contact_item">
                  <div className="top_bar_icon" />
                </div>
                <div className="top_bar_content ml-auto">
                  <div className="top_bar_menu">
                    <ul className="standard_dropdown top_bar_dropdown"></ul>
                  </div>
                  {!token ? (
                    <div className="top_bar_user">
                      <div className="user_icon"></div>
                      <div>
                        <a href="/Register">Register</a>
                      </div>
                      <div>
                        <a href="/Login">Sign in</a>
                      </div>
                    </div>
                  ) : (
                    <div className="top_bar_user">
                      <div className="user_icon"></div>
                      <div>
                        {user.userDetails && (
                          <a href="/Profile">
                            <span className="px-1">Welcome back</span>{" "}
                            {user.userDetails.first_name}{" "}
                          </a>
                        )}
                      </div>
                      <div>
                        <a href="/" onClick={endSession}>
                          Log out
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
        {/* Header Main */}
        <Search />
        {/* Main Navigation */}
        <nav
          className="navbar navbar-expand-lg navbar-light p-2 my-1"
          id={Hero.bb}>
          <section className="container">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarNavDropdown"
              aria-controls="navbarNavDropdown"
              aria-expanded="false"
              aria-label="Toggle navigation">
              <span className="navbar-toggler-icon" />
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
              <ul className="navbar-nav">
                {/* Dropdown menu */}
                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#/"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ opacity: "1.8", color: "white" }}>
                    <span className="">Products</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink">
                    {cat &&
                      cat.map((item, key) => {
                        return (
                          <div key={item.product_category_id} className="">
                            <Link
                              className="dropdown-item"
                              key={key}
                              to={{
                                pathname: `/products/${item.product_category_name}`,
                                state: {
                                  data: item.product_category_id,
                                },
                              }}>
                              {item.product_category_name}
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </li>

                <li className="nav-item dropdown">
                  <a
                    className="nav-link dropdown-toggle"
                    href="#/"
                    id="navbarDropdownMenuLink"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    style={{ opacity: "1.8", color: "white" }}>
                    <span className="">Services</span>
                  </a>
                  <div
                    className="dropdown-menu"
                    aria-labelledby="navbarDropdownMenuLink">
                    {catServe &&
                      catServe.map((item, key) => {
                        return (
                          <div key={item.service_category_id} className="">
                            <Link
                              className="dropdown-item"
                              key={key}
                              to={{
                                pathname: `/services/${item.service_category_name}`,
                                state: {
                                  data: item.service_category_id,
                                },
                              }}>
                              {item.service_category_name}
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/Companies"
                    style={{ opacity: "1.8", color: "white" }}>
                    Companies
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/Sell"
                    style={{ opacity: "1.8", color: "white" }}>
                    <span className="">Sell</span>
                  </a>
                </li>

                <li className="nav-item">
                  <a
                    className="nav-link"
                    href="/ReturnPolicy"
                    style={{ opacity: "1.8", color: "white" }}>
                    Return Process
                  </a>
                </li>
              </ul>
            </div>
          </section>
        </nav>
      </header>
    </div>
  );
};

export default Header;
