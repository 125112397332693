import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import axios from "axios";
import ServicesFilter from "../../Filter/ServicesFilter";
import Imago from "../../img/Image.module.css";
import ReactPagination from "react-paginate";

const Services = (props) => {
  const [id, setId] = useState("");
  const [serCat, setSerCat] = useState([]);
  const [advertising, setAdvertising] = useState([]);
  const [token, setToken] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);

  console.log(props);

  useEffect(() => {
    if (props.location.state) {
      setId(props.location.state.data);
    }
  }, [props.location.state]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    if (id) {
      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/setup/list_service_sub_category/",
          {
            service_cat_uuid: id,
          }
        )
        .then((res) => {
          console.log(res.data);
          setSerCat(res.data.service_sub_category);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/product/get_service_posts/",
          {
            service_cat_id: id,
            record_number: "",
          },
          {
            headers: {
              // "X-SHOPPER-USER-AUTH": token,
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          //   console.log(res.data);
          //   setAdvertising(res.data.service_posts);
          // })
          if (res.data.message === "Record(s) Not Found") {
            setAdvertising([]);
            alert("No Service(s) Found");
          } else {
            console.log(res.data);
            setAdvertising(res.data.product_posts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  const usersPerPage = 16;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = advertising
    ? Math.ceil(advertising.length / usersPerPage)
    : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="page-holder">
      <Header />

      {/* search bar for small screens */}
      <div className="input-group flex-nowrap py-2 px-3" id="sb">
        <a
          href="/AdvanceSearch"
          type="button"
          className="btn btn-primary form-control text-white"
          role="button"
          style={{ backgroundColor: "#0e8ce4" }}>
          Advance Search
        </a>
      </div>
      {/*End of search bar for small screens */}

      <div className="container">
        <section className="py-5">
          <div className="container p-0">
            <div className="row">
              {/* SHOP SIDEBAR*/}
              <div className="col-lg-2 order-2 order-lg-1">
                <h5 className="text-uppercase mb-4">
                  <important>Categories</important>
                </h5>
                <div className="py-2 px-4 bg-dark text-white mb-3">
                  <strong className="small text-uppercase font-weight-bold">
                    Services
                  </strong>
                </div>
                <ul className="list-unstyled small text-muted font-weight-normal mb-5 bg-light">
                  {serCat &&
                    serCat.map((item) => {
                      return (
                        <li className="mb-1" key={item.service_sub_category_id}>
                          <Link
                            className="dropdown-item"
                            to={{
                              pathname: `/service_/${item.sub_category_name}`,
                              state: {
                                data: item.service_sub_category_id,
                              },
                            }}>
                            {item.sub_category_name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>

                <div className="price-range pt-4 mb-5">
                  <ServicesFilter />
                </div>
              </div>
              {/* SHOP LISTING*/}
              <div className="col-lg-10 order-1 order-lg-2 mb-5 mb-lg-0">
                <div className="row mb-3 align-items-center" id="sort">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    {advertising && (
                      <p className="text-small text-muted mb-0">
                        Total {advertising.length}{" "}
                      </p>
                    )}
                  </div>
                </div>
                <div className="row">
                  {/* PRODUCT*/}
                  {advertising &&
                    advertising.map((item) => (
                      <div
                        className="col-lg-3 col-md-3 col-6 p-1"
                        key={item.service_uuid}>
                        <div className="product">
                          <div className="mb-3 position-relative">
                            <div className="badge text-white badge-" />
                            <Link
                              className="d-block"
                              to={{
                                pathname: `/ServicesDetails`,
                                state: { data: item.service_uuid },
                              }}>
                              <img
                                className={Imago.imago}
                                src={item.attached_image}
                                alt="..."
                              />
                            </Link>
                            <div className="product-overlay"></div>
                          </div>
                          <Link
                            to={{
                              pathname: `/Company`,
                              state: { data: item.company.company_uuid },
                            }}
                            className="text-decoration-none d-flex justify-content-start">
                            {item.company.company_name.length < 25
                              ? `${item.company.company_name}`
                              : `${item.company.company_name.substring(
                                  0,
                                  23
                                )}...`}
                          </Link>
                          <br />
                          <Link
                            className="text-dark d-flex justify-content-start"
                            to={{
                              pathname: `/ServicesDetails`,
                              state: { data: item.service_uuid },
                            }}>
                            {item.service_name.length < 20
                              ? `${item.service_name}`
                              : `${item.service_name.substring(0, 19)}...`}
                          </Link>
                        </div>
                        <hr />
                      </div>
                    ))}
                </div>
                {/* PAGINATION*/}
                {advertising && (
                  <div className="container">
                    <ReactPagination
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      breakLabel={"..."}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Services;
