import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Imago from "../img/Image.module.css";

const CompanyServices = (props) => {
  console.log(props);
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    setId(props.data.serviceDetails.company.company_uuid);

    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/shopper/list_company_services/",
        {
          company_id: id,
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            // 'x-user-pass-auth': "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token"
            // "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDetails(res.data.active_services.slice(0, 4));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, props.data.serviceDetails.company.company_uuid, token]);

  return (
    <div>
      <h2 className="h5 text-uppercase mb-4">
        Other Services by {props.data.serviceDetails.company.company_name}
      </h2>
      <div className="row">
        {/* PRODUCT*/}
        {details.length !== 0 && details.length !== undefined ? (
          details.map((detail) => {
            return (
              <div
                className="col-lg-2 col-md-3 col-6 p-1"
                key={detail.service_uuid}>
                <div className="product text-center skel-loader">
                  <div className="d-block mb-3 position-relative">
                    <Link
                      className="d-block"
                      to={{
                        pathname: `/ServicesDetails`,
                        state: { data: detail.service_uuid },
                      }}>
                      <img
                        className={Imago.imago}
                        src={detail.attached_image}
                        alt="..."
                      />
                    </Link>
                    <div className="product-overlay"></div>
                  </div>
                  <h6>
                    {" "}
                    <Link
                      className="reset-anchor"
                      to={{
                        pathname: `/ServicesDetails`,
                        state: { data: detail.service_uuid },
                      }}>
                      {detail.product_name}
                    </Link>
                  </h6>
                  <p className="small text-muted"></p>
                </div>
              </div>
            );
          })
        ) : (
          <div className="text-uppercase">
            <p className="text-center mx-3">No Records Found </p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyServices;
