import React from "react";
import Footer from "../Views/Footer";
// import { Link } from 'react-router-dom'
import ProfileHeader from "./ProfileHeader";

const Return = (props) => {
  console.log(props);

  return (
    <div>
      <ProfileHeader />

      <div className="container pt-3">
        <p>Delivery address</p>
        <p>Contact Details</p>
        <p>Email address</p>
      </div>
      <div className="container py-5">
        <p>Is return of product allowed after delivery?</p>
        <p>No</p>

        <div className="">
          <table className="table table-striped">
            <thead>
              <tr>
                <th scope="col">#</th>
                <th scope="col">Detail</th>
                <th scope="col">Must be return in …</th>
                <th scope="col">No physical defects</th>
                <th scope="col">Sealed</th>
                <th scope="col">Unused</th>
                <th scope="col">Return the Complete package of delivery</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Change of Mind</td>
                <td>Days or week</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Wrong Delivery</td>
                <td>Yes</td>
                <td>Yes</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Parts missing</td>
                <td>Yes</td>
                <td>Yes</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">4</th>
                <td>Product name mis-match</td>
                <td>Yes</td>
                <td>Yes</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">5</th>
                <td>Product size mis-match</td>
                <td>Yes</td>
                <td>Yes</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">6</th>
                <td>Product type mis-match</td>
                <td>Yes</td>
                <td>Yes</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>

              <tr>
                <th scope="row">7</th>
                <td>Broken or damage</td>
                <td>Yes</td>
                <td>Yes</td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="container py-3">
        <form>
          <div className="row pb-2">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Product name"
                readOnly
              />
            </div>
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Reference number"
                readOnly
              />
            </div>
          </div>

          <div className="row pb-2">
            <div className="col">
              <input
                type="text"
                className="form-control"
                placeholder="Any message"
              />
            </div>
          </div>

          <button type="button" className="btn btn-primary btn-sm">
            Submit
          </button>
        </form>
      </div>

      <Footer />
    </div>
  );
};

export default Return;
