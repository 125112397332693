import React, { useState, useEffect } from "react";
import Forgot from "./ForgotPassword.module.css";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import axios from "axios";

const ForgotPassword = () => {
  const [token, setToken] = useState(null);
  const [email, setEmail] = useState("");

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
  }, [token]);

  const SubmitPassword = (e) => {
    e.preventDefault();

    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/shopper/forgot_password/",
        {
          email,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res);
        alert(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div className="container py-5">
      <form className={Forgot.center} onSubmit={SubmitPassword}>
        <h2 className="text-center py-5">FORGOT PASSWORD</h2>

        <div className="card">
          <div className="card-body">
            <p className="card-text py-3">
              Enter the email address Associate with your account and we will
              send you a link to reset your password.
            </p>

            <div className="p-fluid p-formgrid p-grid">
              <div className="p-field p-col">
                <InputText
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <br />
              <div className="p-field p-col">
                <Button label="Submit" />
              </div>
            </div>
          </div>
        </div>

        <div className="text-center">
          <p>
            Dont have an account yet ? <a href="/Register">Register</a>
          </p>
        </div>
      </form>
    </div>
  );
};

export default ForgotPassword;
