import React,{useState, useEffect} from 'react'
import axios from 'axios';



const Filter = () => {

  const [cat, setCat] = useState([]);
  const [catServe, setCatServe] = useState([]);
  const [token, setToken] = useState(null)






  useEffect(() => {
    const tk = sessionStorage.getItem('token');
    setToken(tk);



  axios
    .get(`${process.env.REACT_APP_Base_url}/setup/list_product_category/`)
    .then((res) => {
      console.log(res.data);
      setCat(res.data.product_category);
    })
    .catch((error) => {
      console.log(error);
    });

  axios
    .get(`${process.env.REACT_APP_Base_url}/setup/list_service_category/`)
    .then((response) => {
      setCatServe(response.data.service_category);
    })
    .catch((erro) => {
      console.log(erro);
    });

},[token])

    return (
        <div className="" >
            {/* Button trigger modal */}
            <button type="button" className="form-control btn btn-primary" data-toggle="modal" data-target="#exampleModal">
              Click here to Filter
            </button>
            {/* Modal */}
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title" id="exampleModalLabel">Filter Page</h5>
                    <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">×</span>
                    </button>
                  </div>
                    <div className="modal-body">
                    
                      <div >
                              <div className="container px-5">
                                  <div class="container py-5">
                                      <form>

                                          <div className="form-row">
                                              
                                              <div className="form-group col-md-12">
                                              
                                                  <label htmlFor="inputEmail4">Search by  Product / Service name</label>
                                                  <input type="text" className="form-control" placeholder="Search by Product/ Service name" />
                                                  
                                              </div>
                                          </div>


                                          

                                          
                                          <div className="form-row">
                                              <div className="form-group col-6">
                                                  <label htmlFor="inputAddress2">Price range from</label>
                                                  <input type="number" className="form-control" id="inputAddress2" placeholder="Price range from" />
                                              </div>
                                              <div className="form-group col-6">
                                                  <label htmlFor="inputAddress2">Price range to</label>
                                                  <input type="number" className="form-control" id="inputAddress2" placeholder="Price range to" />
                                              </div>
                                             
                                              
                                          </div><hr />

                                          <div className="form-group">
                                              <label htmlFor="inputAddress">Condition</label>
                                              <div className="form-check">
                                                  <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                  <label className="form-check-label" htmlFor="gridCheck">
                                                      Home used
                                                  </label>
                                              </div>
                                              <div className="form-check">
                                                  <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                  <label className="form-check-label" htmlFor="gridCheck">
                                                      Brand new
                                                  </label>
                                              </div>
                                              <div className="form-check">
                                                  <input className="form-check-input" type="checkbox" id="gridCheck" />
                                                  <label className="form-check-label" htmlFor="gridCheck">
                                                      Not specified
                                                  </label>
                                              </div>
                                          </div><hr />

                                          <div className="form-group col-12">
                                              <label htmlFor="inputAddress">Products</label>
                                              {cat &&
                                                <div className="form-group col-12">
                                                  <select name="account" className="form-control col-12 mb-3" style={{width: 'auto'}}>
                                                    <option value='-----' selected>---</option>
                                                    {
                                                      cat.map((item, key) => {
                                                        return (
                                                          <option value={item.product_category_id} key={key}>{item.product_category_name}</option>
                                                        )
                                                      })
                                                    }
                                                  </select>
                                          
                                              </div>
                                           
                                              }
                                          </div><hr />
                                         

                                          <div className="form-group col-12">
                                              <label htmlFor="inputAddress">Services</label>
                                              {catServe &&
                                                <div className="form-group col-12">
                                                  <select name="account" className="form-control col-12 mb-3" style={{width: 'auto'}}>
                                                    <option value='-----' selected>---</option>
                                                    {
                                                      catServe.map((items, key) => {
                                                        return (
                                                          <option value={items.service_category_id} key={key}>{items.service_category_name}</option>
                                                        )
                                                      })
                                                    }
                                                  </select>
                                          
                                              </div>
                                           
                                              }
                                          </div><hr />


                                          
                                          <div className="form-row">
                                          
                                          <div className="form-group col-12">
                                              <label htmlFor="inputState">Select Company Industry</label>
                                              <select id="inputState" className="form-control">
                                              <option>Select</option>
                                              <option>Fashion/Textile &amp; Print</option>
                                              <option>Beautician </option>
                                              <option>Insurance/Brokerage</option>
                                              <option>Farming/Forestry</option>
                                              <option>Legal/Consultancies</option>
                                              <option>Travel / Tourism</option>
                                              <option>Pharmaceutical/Biotech/Chemicals</option>
                                              <option>Construction/Machinery</option>
                                              <option>Repairs and Restoration</option>
                                              <option>Social Services/Healthcare services</option>
                                              <option>Staffing/Recruiting services</option>
                                              <option>Mining /Construction/Machinery</option>
                                              <option>Government/Public Administration</option>
                                              <option>Manufacturing</option>
                                              <option>Real estate/Building</option>
                                              <option>Publication/Printing/Media</option>
                                              <option>Shipping/Transport/Logistic</option>
                                              <option>Hotel/Catering/Hospitality</option>
                                              <option>Telecommunication/ IT</option>
                                              <option>Furniture/ Office Equip./Handwork</option>
                                              <option>Other services</option>
                                              <option>Education/Lecturing/teaching</option>
                                              <option>Entertainment/ TV/Radio</option>
                                              <option>Wholesales/Trading</option>
                                              <option>Financial Institution/Banking</option>
                                              <option>Other industries </option>
                                              </select>
                                          </div>
                                          
                                          </div><hr />
                                          
                                      </form>
                                  </div>
                              </div>
                      </div>

                    </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary" data-dismiss="modal">Search</button>
                  </div>
                </div>
              </div>
            </div>
        </div>
    )
}

export default Filter
