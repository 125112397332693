import React, { useState, useEffect } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";
import { useHistory } from "react-router-dom";

const Review = (props) => {
  const history = useHistory();
  console.log(props);

  const [company_uuid, setCompanyId] = useState(null);
  const [comment, setComment] = useState("");
  const [rate, setRate] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    if (props.location.state) {
      setCompanyId(props.location.state.id);

      const tk = sessionStorage.getItem("token");
      setToken(tk);
    }
  }, [props.location.state]);
  console.log(company_uuid);

  const handleSubmit = async (e) => {
    e.preventDefault();
    await axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/company/add_review_ratings/",
        {
          company_uuid: company_uuid,
          comment,
          rate,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        alert(response.data.message);
        history.push("/");
      })
      .catch((error) => {
        alert(error);
      });
  };

  return (
    <div className="page-holder py-3">
      <Header />

      <div className="container py-5">
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="exampleFormControlInput1">Note:</label>
            <span className="pl-5 pb-3">
              Please take a minute to review your experience shopping with
            </span>
          </div>

          <div className="form-group">
            <p>How would you rate the Company</p>
            &nbsp;{" "}
            <input
              type="radio"
              id="1 star"
              name="fav_language"
              value={1}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="html">
              1 star{" "}
              <span className="px-4">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-4">(Poor)</span>
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="2 stars"
              name="fav_language"
              value={2}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="css">
              2 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-4">(Weak)</span>{" "}
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="3 stars"
              name="fav_language"
              value={3}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="javascript">
              3 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-4">(Good)</span>{" "}
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="4 stars"
              name="fav_language"
              value={4}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="javascript">
              4 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-2">(Very Good)</span>{" "}
            </label>
            <br />
            &nbsp;{" "}
            <input
              type="radio"
              id="5 stars"
              name="fav_language"
              value={5}
              onChange={(e) => setRate(e.target.value)}
            />
            &nbsp;{" "}
            <label htmlFor="html">
              5 stars{" "}
              <span className="px-3">
                {" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
                <i className="fas fa-star fa-1x small text-warning" />{" "}
              </span>{" "}
              <span className="px-2">(Excellent)</span>{" "}
            </label>
            <br />
          </div>
          <br />

          <div className="form-group">
            <label htmlFor="exampleFormControlTextarea1">
              Write a comment here
            </label>
            <textarea
              className="form-control"
              id="exampleFormControlTextarea1"
              rows={3}
              defaultValue={""}
              onChange={(e) => setComment(e.target.value)}
            />
          </div>

          <input
            className="btn btn-primary"
            type="submit"
            defaultValue="Submit"
          />
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Review;
