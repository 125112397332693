import React, { useState, useEffect } from "react";
import axios from "axios";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import { Link } from "react-router-dom";
import ProductReviews from "../Reviews/ProductReviews";
import RelatedProducts from "../Related Products/RelatedProducts";
import CompanyProducts from "../Related Products/CompanyProduct";
// import Return from "./../User/Return";
import { ProgressSpinner } from "primereact/progressspinner";

const ProductDetails = (props) => {
  console.log(props);

  const [product, setProductDetails] = useState({ productDetails: null });
  const [id, setId] = useState("");
  // const [conditions, setConditions] = useState("");
  const [token, setToken] = useState("");
  const [input, setInput] = useState(1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (props.location.state) {
      setId(props.location.state.data);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (id) {
      setLoading(true);
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/get_product_details/`,
          {
            product_id: id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
              // 'X-SHOPPER-USER-AUTH': token
            },
          }
        )
        .then((res) => {
          console.log(res.data);
          setProductDetails((product) => {
            return { ...product, productDetails: res.data.product_details };
          });
          setLoading(false);
          // setConditions((product) => {
          //   return {
          //     ...product,
          //     productDetails:
          //       res.data.product_details.terms_condition_details
          //         .broken_or_damaged,
          //   };
          // });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  const AddCart = () => {
    console.log(id);
    console.log(product.productDetails.product_uuid);

    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/cart/add_to_cart/`,
          {
            product_id: id,
            quantity: input,
          },
          {
            headers: {
              "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === 201) {
            alert(resp.data.message);
            window.location.reload(true);
          } else {
            alert(resp.data.message);
            window.location.reload(true);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.location.replace("/error");
    }
  };

  const Buynow = () => {
    console.log(id);
    console.log(product.productDetails.product_uuid);

    if (token) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/cart/add_to_cart/`,
          {
            product_id: id,
            quantity: "1",
          },
          {
            headers: {
              // 'x-user-pass-auth': "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token"
              "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((resp) => {
          console.log(resp.data);
          if (resp.data === 201) {
            // alert(resp.data.message);
            window.location.replace("/ProfileCart");
          } else {
            // alert(resp.data.message)
            window.location.replace("/ProfileCart");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      window.location.replace("/error");
    }
  };

  return (
    <div className="page-holder bg-light">
      <Header /> <hr />
      {loading && (
        <ProgressSpinner
          style={{
            width: "50px",
            height: "50px",
            margin: "auto",
            display: "block",
            padding: "30px 0",
          }}
          strokeWidth="8"
          fill="var(--surface-ground)"
          animationDuration=".5s"
        />
      )}
      <section className="py-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              {/* PRODUCT SLIDER*/}
              <div className="row m-sm-0">
                {product.productDetails && !loading && (
                  <div className="col-sm-10 order-1 order-sm-2">
                    <a
                      className="d-block"
                      href={product.productDetails.image_url}>
                      <img
                        className="img-fluid"
                        src={product.productDetails.image_url}
                        alt="..."
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
            {/* PRODUCT DETAILS*/}
            {!loading && product.productDetails && (
              <div className="col-lg-6">
                <Link
                  to={{
                    pathname: `/Company`,
                    state: {
                      data: product.productDetails.company.company_uuid,
                    },
                  }}
                  className="ml-auto font-weight-bold"
                  style={{ fontSize: "2.5rem" }}>
                  {" "}
                  {product.productDetails.company.company_name}{" "}
                </Link>
                <hr />

                <h3>{product.productDetails.product_name}</h3>
                <p className="text-muted lead">
                  GH₵
                  {product.productDetails.discount_amount === null
                    ? `${product.productDetails.price}`
                    : `${product.productDetails.discount_amount.slice(0, 6)}`}
                </p>
                <div className="row align-items-stretch mb-4">
                  <div className="col-sm-5 pr-sm-0">
                    <div className="border d-flex align-items-center justify-content-between py-1 px-3 bg-white border-white">
                      <span className="small text-uppercase text-gray mr-4 no-select">
                        Quantity
                      </span>
                      <div className="quantity">
                        <button className="dec-btn p-0">
                          <i className="fas fa-caret-left" />
                        </button>
                        <input
                          className="form-control border-0 shadow-0 p-0"
                          type="number"
                          onChange={(e) => setInput(e.target.value)}
                          defaultValue={1}
                        />
                        <button className="inc-btn p-0">
                          <i className="fas fa-caret-right" />
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-3 pl-sm-0">
                    <button
                      className="btn btn-dark btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0"
                      onClick={AddCart}>
                      Add to cart
                    </button>
                  </div>
                  <div className="col-sm-3 pl-sm-0">
                    <button
                      className="btn btn-dark btn-sm btn-block h-100 d-flex align-items-center justify-content-center px-0"
                      onClick={Buynow}>
                      Buy now
                    </button>
                  </div>
                </div>
                <br />
                <div className="col-sm-6 pl-sm-0">
                  <input
                    className="form-control border-0 shadow-0 p-0"
                    type="text"
                    placeholder="Enter coupon number if it applies"
                  />
                </div>
                <br />
                <ul className="list-unstyled small d-inline-block">
                  <li className="px-3 py-2 mb-1 bg-white">
                    <strong className="text-uppercase text-dark">
                      Item Returnable:
                    </strong>
                    <a
                      className="reset-anchor ml-2 text-uppercase text-dark"
                      href="#/"
                      id="return">
                      <b>{product.productDetails.returnable}</b>
                    </a>
                  </li>

                  <li className="px-3 py-2 mb-1 bg-white text-muted">
                    <strong className="text-uppercase text-dark">
                      Delivery Available:
                    </strong>
                    <a className="reset-anchor ml-2" href="#/">
                      {product.productDetails.delivery}
                    </a>
                  </li>

                  <li className="px-3 py-2 mb-1 bg-white text-muted">
                    <strong className="text-uppercase text-dark">
                      Items in stock:
                    </strong>
                    <a className="reset-anchor ml-2" href="#/">
                      <strong style={{ color: "red" }}>
                        {product.productDetails.stock_number}
                      </strong>
                    </a>
                  </li>

                  <li className="px-3 py-2 mb-1 bg-white text-muted">
                    <strong className="text-uppercase text-dark">Tag:</strong>
                    <a className="reset-anchor ml-2" href="#/">
                      {product.productDetails.product_type}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* DETAILS TABS*/}
          <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="description-tab"
                data-toggle="tab"
                href="#description"
                role="tab"
                aria-controls="description"
                aria-selected="true">
                Description
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="reviews-tab"
                data-toggle="tab"
                href="#reviews"
                role="tab"
                aria-controls="reviews"
                aria-selected="false">
                Reviews
              </a>
            </li>
          </ul>
          {product.productDetails && (
            <div className="tab-content mb-5" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="description"
                role="tabpanel"
                aria-labelledby="description-tab">
                <div className="p-4 p-lg-5 bg-white">
                  <h6 className="text-uppercase">Product description </h6>
                  <p className="text-muted text-small mb-0">
                    {product.productDetails.description}
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="reviews"
                role="tabpanel"
                aria-labelledby="reviews-tab">
                <ProductReviews product={product} />
              </div>
            </div>
          )}

          {/* {product.productDetails && (
              <div className="tab-content mb-5" id="myTabContent">
                <div
                  className="tab-pane fade show active"
                  id="description"
                  role="tabpanel"
                  aria-labelledby="description-tab">
                  <div className="p-4 p-lg-5 bg-white">
                    <h6 className="text-uppercase">Conditions for Return </h6>
                    <p className="text-muted text-small mb-0">
                      {conditions.map((condition) => {
                        return (
                          <div>
                            <p>{condition}</p>
                          </div>
                        );
                      })}
                    </p>
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="reviews"
                  role="tabpanel"
                  aria-labelledby="reviews-tab">
                  <ProductReviews product={product} />
                </div>
              </div>
            )} */}

          {/* COMPANY PRODUCTS*/}
          {product.productDetails && (
            <CompanyProducts product={product.productDetails} />
          )}

          {/* RELATED PRODUCTS*/}
          {product.productDetails && (
            <RelatedProducts product={product.productDetails} />
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ProductDetails;
