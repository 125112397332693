import React, { Component } from "react";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";

import ActivationPage from "./Components/ActivationPage/ActivationPage";
import AdvanceSearch from "./Components/AdvancePage/AdvanceSearch";
import CartDetails from "./Components/Cart/CartDetails";
import Companies from "./Components/Companies/Companies";
import Company from "./Components/Companies/Company";
import Home from "./Components/Dashboard/Home";
import Login from "./Components/Login/Login";
import Checkout from "./Components/Payment/Checkout";
import Payment from "./Components/Payment/Payment";
import Products from "./Components/Products/General/Products";
import Register from "./Components/Registration/Register";
import RegisterShopper from "./Components/Registration/RegisterShopper";
import Sell from "./Components/Sell/Sell";
import Services from "./Components/Services/General/Services";
import ServicesDetails from "./Components/Details/ServicesDetails";
import Comments from "./Components/User/Comments";
import Profile from "./Components/User/Profile";
import ProfileCart from "./Components/User/ProfileCart";
import Return from "./Components/User/Return";
import Review from "./Components/User/Review";
import ShoppingHistory from "./Components/User/ShoppingHistory";
import ProductDetails from "./Components/Details/ProductDetails";
import ProductSubs from "./Components/Products/Product Subs/ProductSubs";
import ServicesSubs from "./Components/Services/Services Subs/ServicesSubs";
import UpdateProfile from "./Components/Update Profile/UpdateProfile";
import ChangePassword from "./Components/Password/ChangePassword";
import ForgotPassword from "./Components/Password/ForgotPassword";
import Refund from "./Components/Pages/Refund";
import TermsAndCondition from "./Components/Pages/TermsAndCondition";
import Privacy from "./Components/Pages/Privacy";
import CompanyFilter from "./Components/Filter/CompanyFilter";
import ReturnPolicy from "./Components/Pages/ReturnPolicy";
import DisplaySearch from "./Components/AdvancePage/DisplaySearch";
import ScrollToTop from "./ScrollToTop";
import ErrorPage from "./Components/ErrorPage/ErrorPage";

const token = sessionStorage.getItem("token");

class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props;

    return (
      <Route
        {...props}
        render={(props) =>
          !!token ? <Component {...props} /> : <Redirect to="/error" />
        }
      />
    );
  }
}

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop>
        <Switch>
          <Route exact path="/" component={Home} />

          <Route path="/error" component={ErrorPage} />

          <Route path="/Login" component={Login} />

          <Route path="/CartDetails/" component={CartDetails} />

          <Route path="/ProfileCart" component={ProfileCart} />

          <Route path="/Sell" component={Sell} />

          <Route path="/services/" component={Services} />

          <Route path="/service_/" component={ServicesSubs} />

          <Route path="/ServicesDetails" component={ServicesDetails} />

          <Route path="/Register" component={Register} />

          <Route path="/RegisterShopper" component={RegisterShopper} />

          <Route path="/products/" component={Products} />

          <Route path="/product_/" component={ProductSubs} />

          <Route path="/ProductDetails" component={ProductDetails} />

          <Route path="/DisplaySearch" component={DisplaySearch} />

          <Route path="/Companies" component={Companies} />

          <Route path="/Company" component={Company} />

          <Route path="/Review" component={Review} />

          <Route path="/Return" component={Return} />

          <Route path="/Comments" component={Comments} />

          <Route path="/AdvanceSearch" component={AdvanceSearch} />

          <Route path="/Activation" component={ActivationPage} />

          <Route path="/UpdateProfile" component={UpdateProfile} />

          <Route path="/ChangePassword" component={ChangePassword} />

          <Route path="/ForgotPassword" component={ForgotPassword} />

          <Route path="/Refund" component={Refund} />

          <Route path="/TermsAndCondition" component={TermsAndCondition} />

          <Route path="/Privacy" component={Privacy} />

          <Route path="/CompanyFilter" component={CompanyFilter} />

          <Route path="/ReturnPolicy" component={ReturnPolicy} />

          <Route path="/Profile" component={Profile} />

          <ProtectedRoute path="/ShoppingHistory" component={ShoppingHistory} />

          <ProtectedRoute path="/Checkout" component={Checkout} />

          <ProtectedRoute path="/Payment" component={Payment} />
        </Switch>
      </ScrollToTop>
    </BrowserRouter>
  );
}

export default App;
