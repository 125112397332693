import React, {useState, useEffect} from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Imago from '../img/Image.module.css'
import No_Logo from '../img/no_logo.jpeg'
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";


const CompaniesDashboard = () => {

  const [companies , setCompanies] = useState([]);

  //  Get all companies list
  useEffect(() => {
    // const tk = sessionStorage.getItem('token');
    axios
      .get(`${process.env.REACT_APP_Base_url}/shopper/list_companies/`, {
        headers: {
          // 'X-SHOPPER-USER-AUTH': tk
          "x-user-pass-auth":
            "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
        },
      })
      .then((res) => {
        console.log(res);
        setCompanies(res.data.companies.slice(0, 12));
      })
      .catch((err) => {
        console.log(err);
      });

  },[  ])



    return (
      <div>
        <section className="py-5">
          <header>
            <h2 className="h5 text-uppercase mb-4"> companies</h2>
          </header>
          <div className="row blog">
            <div className="col-md-12">
              <>
                <Swiper
                  spaceBetween={10}
                  autoplay={{
                    delay: 6500,
                    disableOnInteraction: false,
                  }}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Autoplay, Pagination, Navigation]}
                  className="mySwiper">
                  <SwiperSlide>
                    <div className="row text-center">
                      {companies.slice(0, 6).map((company) => (
                        <div
                          className="col-lg-2 col-md-3 col-6 p-1"
                          key={company.company_uuid}>
                          <div className="">
                            <Link
                              className=""
                              to={{
                                pathname: `/Company`,
                                state: { data: company.company_uuid },
                              }}>
                              {company.company_logo === "No Logo" ? (
                                <img
                                  className={Imago.comp}
                                  src={No_Logo}
                                  alt={company.company_name}
                                />
                              ) : (
                                <img
                                  className={Imago.comp}
                                  src={company.company_logo}
                                  alt={company.company_name}
                                />
                              )}
                            </Link>

                            <div className="">
                              <small className="card-title">
                                {company.company_name.length < 20
                                  ? `${company.company_name}`
                                  : `${company.company_name.substring(
                                      0,
                                      15
                                    )}...`}
                              </small>
                            </div>
                          </div>
                          <br />
                        </div>
                      ))}
                    </div>
                  </SwiperSlide>
                  <>
                    {companies.length < 8 ? (
                      `${""}`
                    ) : (
                      <SwiperSlide>
                        <div className="row text-center">
                          {companies.slice(7, 12).map((company, index) => (
                            <div
                              className="col-lg-2 col-md-3 col-6 p-1"
                              key={index}>
                              <div className="">
                                <Link
                                  className=""
                                  to={{
                                    pathname: `/Company`,
                                    state: { data: company.company_uuid },
                                  }}>
                                  {company.company_logo === "No Logo" ? (
                                    <img
                                      className={Imago.comp}
                                      src={No_Logo}
                                      alt={company.company_name}
                                    />
                                  ) : (
                                    <img
                                      className={Imago.comp}
                                      src={company.company_logo}
                                      alt={company.company_name}
                                    />
                                  )}
                                </Link>

                                <div className="text-center">
                                  <small className="card-title">
                                    {company.company_name.length < 20
                                      ? `${company.company_name}`
                                      : `${company.company_name.substring(
                                          0,
                                          15
                                        )}...`}
                                  </small>
                                </div>
                              </div>
                              <br />
                            </div>
                          ))}
                        </div>
                      </SwiperSlide>
                    )}
                  </>
                </Swiper>
              </>
            </div>
          </div>
        </section>
        <hr />
      </div>
    );
}

export default CompaniesDashboard
