import React, { useState, useEffect } from "react";
import axios from "axios";

const ServicesFilter = () => {
  const [catServe, setCatServe] = useState([]);
  const [token, setToken] = useState(null);
  const [service, setService] = useState("");
  const [services_id, setServicesId] = useState("");
  const [region, setRegion] = useState("");
  const [regionList, setRegionList] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/get_regions/`, {
        headers: {
          // "X-SHOPPER-USER-AUTH": token,
          "x-user-pass-auth":
            "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
        },
      })
      .then((response) => {
        console.log(response.data);
        setRegionList(response.data.regions);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/list_service_category/`)
      .then((response) => {
        setCatServe(response.data.service_category);
      })
      .catch((erro) => {
        console.log(erro);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/service_filter/`,
        {
          service_name: service,
          service_id: services_id,
          region_id: region,
          offset: "0",
          record_number: "10",
        },
        {
          headers: {
            // "X-SHOPPER-USER-AUTH": token,
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        alert(resp.data);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div className="">
      {/* Button trigger modal */}
      <button
        type="button"
        className="form-control btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        style={{ backgroundColor: "#0e8ce4", color: "#fff" }}>
        Click here to Filter
      </button>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Filter Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="container px-5">
                  <div class="container py-5">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputEmail4">
                            Search by Service name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Service name"
                            onChange={(e) => setService(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="col-12">
                        <label htmlFor="inputAddress">Region of Location</label>
                        {regionList && (
                          <div className="form-group  col-12">
                            <select
                              name="account"
                              className="form-control mb-3"
                              style={{ width: "auto" }}
                              onChange={(e) => setRegion(e.target.value)}>
                              <option value="-----" selected>
                                ---
                              </option>
                              {regionList.map((item, key) => {
                                return (
                                  <option value={item.region_id} key={key}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="col-12">
                        <label htmlFor="inputAddress">Services</label>
                        {catServe && (
                          <div className="form-group col-12">
                            <select
                              name="account"
                              className="form-control col-12 mb-3"
                              style={{ width: "auto" }}
                              onChange={(e) => setServicesId(e.target.value)}>
                              <option value="-----" selected>
                                ---
                              </option>
                              {catServe.map((items, key) => {
                                return (
                                  <option
                                    value={items.service_category_id}
                                    key={key}>
                                    {items.service_category_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="modal-footer">
                        <button type="submit" className="btn btn-primary">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ServicesFilter;
