import React, { useState, useEffect } from "react";
import axios from "axios";
import { Password } from "primereact/password";
import { Button } from "primereact/button";

const ChangePassword = () => {
  const [old_password, setOldPassword] = useState("");
  const [new_password, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    setToken(sessionStorage.getItem("token"));
  }, [token]);

  const settingsHandler = (e) => {
    e.preventDefault(); // Prevent
    if (new_password !== confirmPassword) {
      alert("Passwords don't match");
    } else {
      // make API call
      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/shopper/change_password/",
          {
            new_password,
            old_password,
          },
          {
            headers: {
              "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            alert(res.data.message);
            window.location.reload(true);
          } else {
            alert(res.data.message);
            window.location.reload(true);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div>
      <div>
        {/* Button trigger modal */}
        <button
          type="button"
          className="btn btn-primary"
          data-toggle="modal"
          data-target="#exampleModal">
          click here to Change Password
        </button>
        {/* Modal */}
        <div
          className="modal fade"
          id="exampleModal"
          tabIndex={-1}
          aria-labelledby="exampleModalLabel"
          aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">
                  Change Password Page
                </h5>
                <button
                  type="button"
                  className="close"
                  data-dismiss="modal"
                  aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form className="form-horizontal" onSubmit={settingsHandler}>
                  <div className="card">
                    <div className="card-body">
                      <div className="p-fluid p-formgrid p-grid">
                        <div className="p-field p-col">
                          <h5>Current Password</h5>
                          <Password
                            required
                            value={old_password}
                            onChange={(e) => setOldPassword(e.target.value)}
                            toggleMask
                          />
                        </div>
                        <br />
                        <div className="p-field p-col">
                          <h5>New Password</h5>
                          <Password
                            required
                            value={new_password}
                            onChange={(e) => setNewPassword(e.target.value)}
                            toggleMask
                          />
                        </div>
                        <br />
                        <div className="p-field p-col">
                          <h5>Confirm Password</h5>
                          <Password
                            required
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            toggleMask
                          />
                        </div>
                        <br />
                        <div className="p-field p-col">
                          <Button label="Submit" />
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div className="modal-footer"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
