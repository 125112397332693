import React, {  useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import Footer from '../Views/Footer'
import './Profile.css'
import ProfileHeader from './ProfileHeader';

 const Profile = () => {


  const [state, setState]=useState({
    userDetails: null
  });
  const [token, setToken] = useState(null);

  


  //  Get shopper details
  useEffect(() => {
    const tk = sessionStorage.getItem('token');
    setToken(tk);

    axios
      .get(
        "https://rosmallonline.com:8000/ecoms-api/shopper/view_shopper_details/",
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setState((state) => {
          return { ...state, userDetails: res.data.shopper_details };
        });
      })
      .catch((err) => {
        console.log(err);
      });

  },[token])
  
        return (
            
            <div className="page-holder">
            
              <ProfileHeader />
          
              <div className="container pb-5" >
                <div className="row justify-content-center">
                  <div className="col-12 ">
                    <div className="my-4">
                      <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="false">Profile</a>
                        </li>
                      </ul>
                        <div className="row mt-5 align-items-center">
                          
                         {state.userDetails && 
                          
                          <div className="col-lg-12 pb-5">
                           <div className="row">
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="first_name">First name</label>
                               <input className="form-control form-control-lg" name="first_name"  placeholder={state.userDetails.first_name} readOnly/>
                             </div>
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="middle_name">Middle name</label>
                               <input className="form-control form-control-lg" name="middle_name"   type="text" placeholder={state.userDetails.middle_name} readOnly />
                             </div>

                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="last_name">Last name</label>
                               <input className="form-control form-control-lg" name="last_name"   type="text" placeholder={state.userDetails.last_name} readOnly />
                             </div>
                             
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="country">Country</label>
                               <input className="form-control form-control-lg" name="country"   type="text" placeholder={state.userDetails.country} readOnly />
                             </div>

                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="email">Email address</label>
                               <input className="form-control form-control-lg" name="email"   type="email" placeholder={state.userDetails.email} readOnly />
                             </div>

                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="gps_number">GPS Number</label>
                               <input className="form-control form-control-lg" name="gps_number"   type="text" placeholder={state.userDetails.gps} readOnly />
                             </div>

                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="location">Location</label>
                               <input className="form-control form-control-lg" name="location"   type="text" placeholder={state.userDetails.location} readOnly />
                             </div>

                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="city">City</label>
                               <input className="form-control form-control-lg" name="city"  type="text" placeholder={state.userDetails.city} readOnly />
                             </div>
                             
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="postcode">Postcode</label>
                               <input className="form-control form-control-lg" name="postcode"  type="text" placeholder={state.userDetails.postcode} readOnly />
                             </div>
                             
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="dob">Date of Birth</label>
                               <input className="form-control form-control-lg" name="dob"   type="text" placeholder={state.userDetails.dob} readOnly />
                             </div>
                             
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="street">Street</label>
                               <input className="form-control form-control-lg" name="street"  type="text" placeholder={state.userDetails.street} readOnly/>
                             </div>
                             
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="phone">Phone</label>
                               <input className="form-control form-control-lg" name="phone" type="tel" placeholder={state.userDetails.phone}  readOnly  />
                             </div>
                             
                             <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="phone">Gender</label>
                               <input className="form-control form-control-lg" name="phone" type="tel" placeholder={state.userDetails.gender}  readOnly  />
                             </div>
                             
                           </div>
                          </div>
                          }
                        </div>
                        
                       
                    </div>
                  </div>
                </div>
              </div>
          
              <Footer />
            
            </div>

        );
    }


export default Profile;
