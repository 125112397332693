import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Imago from "../img/Image.module.css";

const ServicesDashboard = () => {
  const [services, setServices] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    axios
      .get(`${process.env.REACT_APP_Base_url}/shopper/list_posted_services/`, {
        headers: {
          // "X-SHOPPER-USER-AUTH": tk,
          "x-user-pass-auth":
            "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
        },
      })
      .then((res) => {
        console.log(res.data);
        setServices(res.data.active_posted_services.slice(0, 8));
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <div>
      <section className="py-5">
        <header>
          <h2 className="h5 text-uppercase mb-4"> Services</h2>
        </header>
        <div className="row blog">
          <div className="col-md-12">
            <div className="row">
              {services.map((service) => (
                <div
                  className="col-lg-2 col-md-3 col-6"
                  key={service.service_uuid}>
                  <div className="">
                    <Link
                      to={{
                        pathname: "/ServicesDetails",
                        state: { data: service.service_uuid },
                      }}>
                      <img
                        src={service.attached_image}
                        alt="Image1"
                        className={Imago.imago}
                        style={{ maxWidth: "100%" }}
                      />
                    </Link>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <br />
      </section>
    </div>
  );
};

export default ServicesDashboard;
