import React, { useState, useEffect } from "react";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import { Link } from "react-router-dom";
import axios from "axios";
import ServicesFilter from "../../Filter/ServicesFilter";
import Imago from "../../img/Image.module.css";
import ReactPagination from "react-paginate";

const ServicesSubs = (props) => {
  const [id, setId] = useState(null);
  const [token, setToken] = useState(null);
  const [pageNumber, setPageNumber] = useState(0);
  const [servicesSubs, setServicesSubs] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    if (props.location.state) {
      setId(props.location.state.data);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/product/get_service_sub_category_posts/",
          {
            service_sub_cat_id: id,
            record_number: "",
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
              // "X-SHOPPER-USER-AUTH": token,
            },
          }
        )
        .then((response) => {
          //   console.log(response.data);
          //
          // })
          if (response.data.message === "Record(s) Not Found") {
            setServicesSubs([]);
            alert("No Service(s) Found");
          } else {
            console.log(response.data);
            setServicesSubs(response.data.service_sub_posts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  const usersPerPage = 18;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = servicesSubs
    ? Math.ceil(servicesSubs.length / usersPerPage)
    : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="page-holder">
      <Header />

      {/* search bar for small screens */}
      <div className="input-group flex-nowrap py-2 px-3" id="sb">
        <a
          href="/AdvanceSearch"
          type="button"
          className="btn btn-primary form-control text-white"
          role="button"
          style={{ backgroundColor: "#0e8ce4" }}>
          Advance Search
        </a>
      </div>
      {/*End of search bar for small screens */}

      <div className="container">
        <section className="py-5">
          <div className="container p-0">
            <div className="row">
              {/* SHOP SIDEBAR*/}
              <div className="col-lg-2 order-2 order-lg-1">
                <h5 className="text-uppercase mb-4">Categories</h5>
                <div className="py-2 px-4 bg-dark text-white mb-3">
                  <strong className="small text-uppercase font-weight-bold">
                    Services
                  </strong>
                </div>

                <div className="price-range pt-4 mb-5">
                  <ServicesFilter />
                </div>
              </div>
              {/* SHOP LISTING*/}
              <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">
                <div className="row mb-3 align-items-center" id="sort">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    {servicesSubs && (
                      <p className="text-small text-muted mb-0">
                        Showing {servicesSubs.length} results
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-inline d-flex align-items-center justify-content-lg-end mb-0">
                      <li className="list-inline-item text-muted mr-3">
                        <Link className="reset-anchor p-0" to="#">
                          <i className="fas fa-th-large" />
                        </Link>
                      </li>
                      <li className="list-inline-item text-muted mr-3">
                        <Link className="reset-anchor p-0" to="#">
                          <i className="fas fa-th" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <select
                          className="selectpicker ml-auto"
                          name="sorting"
                          data-width={200}
                          data-style="bs-select-form-control"
                          data-title="Default sorting">
                          <option value="default">Default sorting</option>
                          <option value="Cameras">Cameras</option>
                          <option value="Photos">Photos</option>
                          <option value="Phones">Phones</option>
                          <option value="Watches">Watches</option>
                          <option value="computers">computers</option>
                          <option value="tablets">tablets</option>
                          <option value="Headphones">Headphones</option>
                          <option value="Surveillance">Surveillance</option>
                          <option value="TV">TV</option>
                          <option value="Speaker and Audio">
                            Speaker and Audio
                          </option>
                          <option value="Games">Games</option>
                          <option value="Ink /toner">Ink /toner</option>
                          <option value="Security Accessories">
                            Security Accessories
                          </option>
                          <option value="Softwares">Softwares</option>
                          <option value="Drones">Drones</option>
                          <option value="Network">Network</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  {servicesSubs &&
                    servicesSubs.map((items) => (
                      <div
                        className="col-lg-3 col-md-4 col-6 p-1"
                        key={items.service_uuid}>
                        <div className="product text-center">
                          <div className="mb-3 position-relative">
                            <div className="badge text-white badge-" />
                            <Link
                              className="d-block"
                              to={{
                                pathname: `/ServicesDetails`,
                                state: { data: items.service_uuid },
                              }}>
                              <img
                                className={Imago.imago}
                                src={items.attached_image}
                                alt="..."
                              />
                            </Link>
                            <div className="product-overlay"></div>
                          </div>
                          <Link
                            to={{
                              pathname: `/Company`,
                              state: { data: items.company.company_uuid },
                            }}
                            className="text-decoration-none">
                            {" "}
                            {items.company.company_name}{" "}
                          </Link>
                          <hr />
                          <h6 className="text-center pb-1">
                            {" "}
                            {items.service_name}
                          </h6>
                        </div>
                      </div>
                    ))}
                </div>
                {/* PAGINATION*/}
                {servicesSubs && (
                  <div className="container">
                    <ReactPagination
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      breakLabel={"..."}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default ServicesSubs;
