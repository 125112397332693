import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom';
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";

const Checkout = (props) => {
  console.log(props);
  const [token, setToken] = useState(null);
  const [display, setDisplay] = useState(false);
  const [cost, setCost] = useState(null);
  const [cartList, setCartList] = useState([]);
  const [list, setList] = useState([]);
  const [state, setState] = useState({
    userDetails: null,
  });
  // const [listId , setListId] = useState([])

  let listId = [];

  if (list !== null) {
    list.forEach(function (value) {
      console.log(value.cart_uuid);
      listId.push(value.cart_uuid);
    });
  }

  // console.log(listId);
  // console.log(process.env);
  // console.log(process.env.REACT_APP_Payment_key);

  useEffect(() => {
    setList(props.location.state.data);
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(
        "https://rosmallonline.com:8000/ecoms-api/shopper/view_shopper_details/",
        {
          headers: {
            // "x-user-pass-auth":
            //   "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setState((state) => {
          return { ...state, userDetails: res.data.shopper_details };
        });
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/cart/tag_cart_items/",
        {
          cart_ids: listId,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        if (res.data.status === 201) {
          console.log(res.data);
          setCost(res.data.payment_details);
        }
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://rosmallonline.com:8000/ecoms-api/cart/shopper_cart/", {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCartList(res.data.shopper_cart_data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [list, props.location.state.data, token]);

  const Edit = () => {
    setDisplay(!display);
  };

  const Payment = async () => {
    await axios
      .post(
        "https://app.mybusinesspay.com/payment/v1.0/initialize",
        {
          metadata: {
            product_name: cost.cart_tag_id,
            product_description: state.userDetails.profile_id,
          },
          callback: "https://shopper.rosjob.tech/",
          post_url:
            "https://rosmallonline.com:8000/ecoms-api/payment/cart_payment_callback/",
          pub_key: process.env.REACT_APP_Pub_key,
          first_name: state.userDetails.first_name,
          currency: "GHS",
          amount: cost.grand_total_amount,
          last_name: state.userDetails.last_name,
          phone_number: state.userDetails.phone,
          email: state.userDetails.email,
        },
        {
          headers: {
            Authorization: process.env.REACT_APP_Payment_key,
          },
        }
      )
      .then((response) => {
        // console.log(response);
        if (response.data.status === "success") {
          window.location.replace(response.data.url);
          // console.log(response);
        } else {
          window.location.replace(response.data.url);
          // console.log(response);
        }
      })
      .catch((error) => {
        alert("Please add an item and try again");
        console.error(error);
      });
  };

  console.log(list);

  return (
    <div className="page-holder">
      <Header />

      <div className="container">
        {/* HERO SECTION*/}

        <div className="row py-3">
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="card py-2">
              <label className="h6 pl-1">Select Delivery Option</label>
              <div className="card-body">
                <div className="form-check ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault1"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault1">
                    Cross Region – subject to additional charges (You will be
                    contacted)
                  </label>
                </div>

                <div className="form-check ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="flexRadioDefault"
                    id="flexRadioDefault2"
                    defaultChecked
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault2">
                    Standard – Within the city the seller operate (X business
                    days) - GHC XX
                  </label>
                </div>

                <div className="form-check ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Fast"
                    id="flexRadioDefault3"
                  />
                  <label
                    className="form-check-label"
                    htmlFor="flexRadioDefault3">
                    Fast delivery (Same day delivery available only in the same
                    city) - GHC XX
                  </label>
                </div>
                <div className="form-check ml-3">
                  <input
                    className="form-check-input"
                    type="radio"
                    name="Self"
                    id="flexRadioDefault4"
                    defaultChecked
                  />
                  <label className="form-check-label" htmlFor="Self">
                    Self pickup – Free
                  </label>
                </div>
              </div>
            </div>
            <br />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="card">
              <div className="card-body py-2">
                <label className="h6">Select Payment Choice</label>

                <div>
                  <div className="form-check ml-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault9"
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault9">
                      Pay on Delivery
                    </label>
                  </div>
                  <div className="form-check ml-3">
                    <input
                      className="form-check-input"
                      type="radio"
                      name="flexRadioDefault"
                      id="flexRadioDefault8"
                      defaultChecked
                    />
                    <label
                      className="form-check-label"
                      htmlFor="flexRadioDefault8">
                      Pay online
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />

        {state.userDetails && (
          <div className="row mt-5 align-items-center">
            <div className="col-12">
              <div className="card">
                <div className="card-body">
                  <p className="small">
                    {state.userDetails.first_name} {state.userDetails.last_name}{" "}
                  </p>
                  <p className="small">{state.userDetails.street}</p>
                  <p className="small">{state.userDetails.location}</p>
                  <p className="small">{state.userDetails.gps}</p>
                  <p className="small">{state.userDetails.region}</p>
                  <p className="small">{state.userDetails.phone}</p>
                  <p className="small">{state.userDetails.country}</p>
                  <button className="btn btn-primary" onClick={Edit}>
                    Alternative address{" "}
                  </button>
                </div>
                {/* <div className="float-end">
                            <button className="btn btn-primary justify-content-end">Edit </button>
                         </div> */}
                {/* <div className="col-4">
                        <button className="btn btn-primary" onClick={Edit}>Add an alternative address </button>
                       </div> */}
              </div>
              <div className="row mb-4"></div>
            </div>
          </div>
        )}

        <section className="pb-5">
          {/* BILLING ADDRESS*/}
          <h2 className="h5 text-uppercase mb-4">
            Alternative delivery address
          </h2>

          <div className="row">
            {state.userDetails && display && (
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="firstName">
                      First name
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="firstName"
                      type="text"
                      placeholder={state.userDetails.first_name}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, first_name: e.target.value };
                        })
                      }
                    />
                  </div>
                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="lastName">
                      Other name
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="lastName"
                      type="text"
                      placeholder={state.userDetails.middle_name}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, middle_name: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="lastName">
                      Last name
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="lastName"
                      type="text"
                      placeholder={state.userDetails.last_name}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, last_name: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="street">
                      Street
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="street"
                      type="text"
                      placeholder={state.userDetails.street}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, street: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="street">
                      gps
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="street"
                      type="text"
                      placeholder={state.userDetails.gps}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, gps: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="phone">
                      Location
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="phone"
                      type="text"
                      placeholder={state.userDetails.location}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, location: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="phone">
                      Region
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="phone"
                      type="text"
                      placeholder={state.userDetails.region}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, region: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="phone">
                      Phone number
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="phone"
                      type="tel"
                      placeholder={state.userDetails.phone}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, phone: e.target.value };
                        })
                      }
                    />
                  </div>

                  <div className="col-lg-6 form-group">
                    <label
                      className="text-small text-uppercase"
                      htmlFor="address">
                      Country
                    </label>
                    <input
                      className="form-control form-control-lg"
                      id="address"
                      type="text"
                      placeholder={state.userDetails.country}
                      onChange={(e) =>
                        setState((state) => {
                          return { ...state, country: e.target.value };
                        })
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            {/* ORDER SUMMARY*/}
            <div className="col-lg-4">
              <div className="card border-0 rounded-0 p-lg-4 bg-light">
                <div className="card-body">
                  <h5 className="text-uppercase mb-4">Your order</h5>
                  <ul className="list-unstyled mb-0">
                    {cartList &&
                      cartList.map((item, index) => {
                        return (
                          <li
                            key={index}
                            className="d-flex align-items-center justify-content-between">
                            <strong className="small font-weight-bold">
                              {item.product.product_name}
                            </strong>
                            <span className="text-muted small">
                              GH₵ {item.total}
                            </span>
                          </li>
                        );
                      })}

                    <li className="border-bottom my-2" />
                    {cost && (
                      <li className="d-flex align-items-center justify-content-between">
                        <strong className="text-uppercase small font-weight-bold">
                          Total
                        </strong>
                        <span>GH₵ {cost.grand_total_amount}</span>
                      </li>
                    )}
                    <div className="container-fluid py-5">
                      <h5 className="text-uppercase mb-4"> Payment Channels</h5>
                      <img
                        src="./img/alll.png"
                        className="img-fluid"
                        alt="all"
                      />
                      <ul className="list-unstyled mb-0 py-3">
                        <li className="border-bottom my-2" />
                        <li className="d-flex align-items-center justify-content-between">
                          <strong className="text-uppercase small font-weight-bold">
                            Bank Transfer
                          </strong>
                          <span />
                        </li>
                        <li className="border-bottom my-2" />
                      </ul>
                    </div>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-lg-12 form-group py-3">
              {/* <Link className="btn btn-dark" to="/Payment" type="submit">Place order</Link> */}
              <button
                className="btn btn-primary"
                type="submit"
                onClick={Payment}>
                Place order
              </button>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Checkout;
