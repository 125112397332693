import axios from 'axios';
import React,{useState, useEffect} from 'react';
import Footer from '../Views/Footer'
import Header from '../Views/Header';
import { useHistory } from 'react-router-dom';




const RegisterShopper = () => {

  useEffect(() => {

    axios.get('https://rosmallonline.com:8000/ecoms-api/setup/get_regions/')
    .then(res => {
      console.log(res.data.regions[0].region_id);
      const region_id = res.data.regions[0].region_id;
      setRegionId(region_id);
    })
    .catch((err) => {
      console.error(err);
    })


    axios.get('https://rosmallonline.com:8000/ecoms-api/setup/get_countries/')
      .then(res => {
        console.log(res.data.country[0])
      }).catch((err) => {
        console.error(err);
      })
  }, [])
   
    const history = useHistory();
    const [first_name,setFirstName] = useState(''); 
    const [middle_name,setMiddleName] = useState(''); 
    const [last_name, setLastName] = useState('');
    const [country ,setCountry] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [gps_number,setGpsNumber] = useState('');
    const [address,setAddress] = useState('');
    const [location,setLocation] = useState('');
    const [city,setCity] = useState('');
    const [postcode, setPostcode] = useState('');
    const [dob, setDob] = useState('');
    const [street,setStreet] = useState('');
    const [region_id, setRegionId] = useState('');
    const [phone, setPhone] = useState('');
    const [gender, setGender] = useState('');
  
  const  submitHandler = async (e) => {
    e.preventDefault();
    
  await axios.post('https://rosmallonline.com:8000/ecoms-api/shopper/add_shopper/', {
 
          first_name,
          middle_name,
          last_name,
          country,
          email,
          password,
          gps_number,
          address,
          location,
          city,
          postcode,
          dob,
          street,
          region_id,
          phone,
          gender
     
      }).then(response => {
        console.log(response);
        if(response.data.status === 201){
          console.log("Redirect successful");
          alert(response.data.message);
          history.push('/Login')
      } else {
          console.log("Redirect failed");
          alert(response.data.message)
          // history.push('/register')    
      }
      });
        
    }


 
    
    
        return (
            <div className="page-holder">
        
              <Header />
                
              <div className="container py-5">
                <section className="content">
                  <h2 className="h5 text-uppercase mb-4">Registration Page details</h2>
                  <div className="row">
                    <div className="container">
                      <div className="container">
                        <div className="col-lg-12">
                          <form onSubmit={submitHandler}>
                            <div className="row">
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="first_name">First name</label>
                                <input className="form-control form-control-lg" name="first_name" onChange={e => setFirstName(e.target.value)} type="text" placeholder="Enter your first name" />
                              </div>
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="middle_name">Middle name</label>
                                <input className="form-control form-control-lg" name="middle_name"  onChange={e => setMiddleName(e.target.value)} type="text" placeholder="Enter your last name" />
                              </div>

                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="last_name">Last name</label>
                                <input className="form-control form-control-lg" name="last_name"  onChange={e => setLastName(e.target.value)} type="text" placeholder="Enter your last name" />
                              </div>
                              
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="country">Country</label>
                                <input className="form-control form-control-lg" name="country"  onChange={e => setCountry(e.target.value)} type="text" placeholder="Enter your country name" />
                              </div>

                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="email">Email address</label>
                                <input className="form-control form-control-lg" name="email"  onChange={e => setEmail(e.target.value)} type="email" placeholder="e.g. Jason@example.com" />
                              </div>

                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="password">Password</label>
                                <input className="form-control form-control-lg" name="password"  onChange={e => setPassword(e.target.value)} type="password" placeholder="**********" />
                              </div>
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="gps_number">GPS Number</label>
                                <input className="form-control form-control-lg" name="gps_number"  onChange={e => setGpsNumber(e.target.value)} type="text" placeholder="gps_number" />
                              </div>
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="address">Address </label>
                                <input className="form-control form-control-lg" name="address"  onChange={e => setAddress(e.target.value)} type="text" placeholder="House number and street name" />
                              </div>

                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="location">Location</label>
                                <input className="form-control form-control-lg" name="location"  onChange={e => setLocation(e.target.value)} type="text" placeholder="Apartment, Suite, Unit, etc (optional)" />
                              </div>

                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="city">City</label>
                                <input className="form-control form-control-lg" name="city" onChange={e => setCity(e.target.value)} type="text" placeholder="Enter your city name" />
                              </div>
                              
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="postcode">Postcode</label>
                                <input className="form-control form-control-lg" name="postcode"  onChange={e => setPostcode(e.target.value)} type="text" placeholder="Enter your postcode" />
                              </div>
                              
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="dob">Date of Birth</label>
                                <input className="form-control form-control-lg" name="dob" onChange={e => setDob(e.target.value)} type="text" placeholder="e.g. 02-02-2020" />
                              </div>
                              
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="street">Street</label>
                                <input className="form-control form-control-lg" name="street" onChange={e => setStreet(e.target.value)} type="text" placeholder="e.g. Circle" />
                              </div>
                              
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="phone">Phone</label>
                                <input className="form-control form-control-lg" name="phone" onChange={e => setPhone(e.target.value)} type="tel" />
                              </div>

                              <div className="col-lg-6 form-group">
                              <div className="form-group">
                                <label>Gender</label>
                                <select className="form-control" value={gender} onChange={e => setGender(e.target.value)} >
                                  <option>---</option>
                                  <option>Male</option>
                                  <option>Female</option>
                                </select>
                              </div>
                              </div>
                              
                              <div className="col-lg-12 form-group">
                                <button className="btn btn-dark" type="submit">Register</button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div> 
                  </div>
                </section>
              </div>
      
              <Footer />

            </div>
        );
    
}

export default RegisterShopper;
