import React, { useState, useEffect } from "react";
import Footer from "../Views/Footer";
import "../User/Profile.css";
import axios from "axios";
import ProfileHeader from "../User/ProfileHeader";
import ChangePassword from "../Password/ChangePassword";

const UpdateProfile = () => {
  const [token, setToken] = useState(null);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get("https://rosmallonline.com:8000/ecoms-api/setup/get_regions/")
      .then((res) => {
        console.log(res.data);
        console.log(res.data.regions[0].region_id);
        const region_id = res.data.regions[0].region_id;
        setRegionId(region_id);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get("https://rosmallonline.com:8000/ecoms-api/setup/get_countries/")
      .then((res) => {
        console.log(res.data.country[0]);
      })
      .catch((err) => {
        console.error(err);
      });

    axios
      .get(
        "https://rosmallonline.com:8000/ecoms-api/shopper/view_shopper_details/",
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setUser(res.data.shopper_details);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  // const [first_name,setFirstName] = useState('');
  // const [middle_name,setMiddleName] = useState('');
  // const [last_name, setLastName] = useState('');
  // const [country ,setCountry] = useState('');
  // const [email, setEmail] = useState('');
  // const [gps_number,setGpsNumber] = useState('');
  // const [address,setAddress] = useState('');
  // const [location,setLocation] = useState('');
  // const [city,setCity] = useState('');
  // const [postcode, setPostcode] = useState('');
  // const [dob, setDob] = useState('');
  // const [street,setStreet] = useState('');
  const [region_id, setRegionId] = useState("");
  // const [phone, setPhone] = useState('');

  const submitHandler = async (e) => {
    e.preventDefault();

    await axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/shopper/edit_shopper_account/",
        {
          first_name: user.first_name,
          middle_name: user.middle_name,
          last_name: user.last_name,
          country: user.country,
          email: user.email,
          gps_number: user.gps,
          address: user.address,
          location: user.location,
          city: user.city,
          postcode: user.postcode,
          dob: user.dob,
          street: user.street,
          region_id: region_id,
          phone: user.phone,
          gender: user.gender,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        console.log(response);
        if (response.data.status === 201) {
          console.log("Redirect successful");
          alert(response.data.message);
          window.location.reload(true);
        } else {
          console.log("Redirect failed");
          alert(response.data.message);
          window.location.reload(true);

          // history.push('/register')
        }
      });
  };

  return (
    <div>
      <div className="page-holder">
        <ProfileHeader />

        <div className="container pb-5">
          <div className="row justify-content-center">
            <div className="col-12 ">
              <div className="my-4">
                <ul className="nav nav-tabs mb-4" id="myTab" role="tablist">
                  <li className="nav-item">
                    <a
                      className="nav-link active"
                      id="home-tab"
                      data-toggle="tab"
                      href="#home"
                      role="tab"
                      aria-controls="home"
                      aria-selected="false">
                      Update Profile
                    </a>
                  </li>
                </ul>

                {user && (
                  <div className="col-lg-12">
                    <form onSubmit={submitHandler}>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="first_name">
                            First name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="first_name"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, first_name: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.first_name}
                          />
                        </div>
                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="middle_name">
                            Middle name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="middle_name"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, middle_name: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.middle_name}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="last_name">
                            Last name
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="last_name"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, last_name: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.last_name}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="country">
                            Country
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="country"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, country: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.country}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="email">
                            Email address
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="email"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, email: e.target.value };
                              })
                            }
                            type="email"
                            placeholder={user.email}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="gps_number">
                            GPS Number
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="gps_number"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, gps: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.gps}
                          />
                        </div>
                        {/* <div className="col-lg-6 form-group">
                               <label className="text-small text-uppercase" htmlFor="Region">Region </label>
                               <input className="form-control form-control-lg" name="address"  onChange={e => setUser((user) => {return {...user,region: e.target.value}}) } type="text" placeholder={user.region} />
                             </div> */}

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="location">
                            Location
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="location"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, location: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.location}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="city">
                            City
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="city"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, city: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.city}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="postcode">
                            Postcode
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="postcode"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, postcode: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.postcode}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="dob">
                            Date of Birth
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="dob"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, dob: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.dob}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="street">
                            Street
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="street"
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, street: e.target.value };
                              })
                            }
                            type="text"
                            placeholder={user.street}
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <label
                            className="text-small text-uppercase"
                            htmlFor="phone">
                            Phone
                          </label>
                          <input
                            className="form-control form-control-lg"
                            name="phone"
                            type="tel"
                            placeholder={user.phone}
                            onChange={(e) =>
                              setUser((user) => {
                                return { ...user, phone: e.target.value };
                              })
                            }
                          />
                        </div>

                        <div className="col-lg-6 form-group">
                          <div className="form-group">
                            <label>Gender</label>
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setUser((user) => {
                                  return { ...user, gender: e.target.value };
                                })
                              }>
                              <option>---</option>
                              <option>Male</option>
                              <option>Female</option>
                            </select>
                          </div>
                        </div>

                        <div className="col-lg-12 form-group">
                          <button className="btn btn-dark" type="submit">
                            Update
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                )}

                <section className="py-5">
                  <p className="py-3">To change password</p>
                  <ChangePassword />
                </section>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default UpdateProfile;
