import React, {  } from 'react'
import Footer from '../Views/Footer';
import Header from '../Views/Header';
import { Link} from 'react-router-dom';

 const Register = () => {
        return (
            <div style={{height: '100vh'}}>
                <div className="page-holder">
        {/* navbar*/}
        {/* Header */}
                    <Header />
                    
        <div className="container py-5" style={{ height: '60vh' }}>
          <section className="py-5">
            <div className="row">
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">As a Shopper</h5>
                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <Link to="/RegisterShopper" className="btn btn-primary">Register Here</Link>
                  </div>
                </div>
              </div>
              <div className="col-sm-6">
                <div className="card">
                  <div className="card-body">
                    <h5 className="card-title">As a Company / Organization</h5>
                    <p className="card-text">With supporting text below as a natural lead-in to additional content.</p>
                    <Link to="/Sell" className="btn btn-primary">Register Here</Link>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>

                    <Footer />
      </div>

            </div>
        );
}

export default Register;
