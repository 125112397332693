import React from "react";
import Styling from "./ErroPage.module.css";
import { Link } from "react-router-dom";
import err from "../img/705.png";

const ErrorPage = () => {
  return (
    <div>
      <div className={Styling.container}>
        <div className="text-center">
          <img
            src={err}
            // src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTByInV4yyVjEQV9rtzks2NXOee-Uh6FFISgA&usqp=CAU"
            alt="baby"
            width={350}
            height={350}
          />
          <h3>Sorry the page you looking for is not accessible to you </h3>
          <h6>Please Login to have access to it </h6>
          <Link to="/login" style={{ fontSize: "16px" }}>
            Click here to Login
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ErrorPage;
