import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Imago from "../img/Image.module.css";

const TrendingDashboard = () => {
  const [trending, setTrending] = useState([]);

  const [token, setToken] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(`${process.env.REACT_APP_Base_url}/shopper/get_trending_posts/`, {
        headers: {
          // "X-SHOPPER-USER-AUTH": token,
          "x-user-pass-auth":
            "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
        },
      })
      .then((res) => {
        console.log(res.data);
        setTrending(res.data.trending_products.slice(0, 12));
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  return (
    <div>
      {/* <section className="py-5">
        <header>
          <h2 className="h5 text-uppercase mb-4"></h2>
        </header>
      
      </section> */}
      <section className="py-5">
        <header>
          <h2 className="h5 text-uppercase mb-4"> Top trending products</h2>
        </header>
        {trending && (
          <div className="row blog">
            <div className="col-md-12">
              <div className="row">
                {trending.map((item) => (
                  // <div
                  //   className="col-lg-3 col-md-4 col-6"
                  //   key={item.trending_uuid}>
                  //   <div className="">
                  //     <Link
                  //       to={{
                  //         pathname: "/ProductDetails",
                  //         state: { data: item.product.product_uuid },
                  //       }}>
                  //       <img
                  //         src={item.product.image_url}
                  //         alt="Image1"
                  //         className={Imago.imago}
                  //         style={{ maxWidth: "100%" }}
                  //       />
                  //     </Link>
                  //   </div>
                  // </div>

                  <div
                    className="col-lg-2 col-md-3 col-6 p-1"
                    key={item.trending_uuid}>
                    <div className="product">
                      <div className="mb-3 position-relative">
                        <div className="badge text-white" />
                        <Link
                          className="d-block"
                          to={{
                            pathname: `/ProductDetails`,
                            state: { data: item.product.product_uuid },
                          }}>
                          <img
                            className={Imago.imago}
                            src={item.product.image_url}
                            alt="..."
                          />
                        </Link>
                        <div className="product-overlay"></div>
                      </div>
                      <h6 className="mb-0">
                        <Link
                          className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                          to={{
                            pathname: `/ProductDetails`,
                            state: { data: item.product.product_uuid },
                          }}>
                          {item.product.product_name.length < 23
                            ? `${item.product.product_name}`
                            : `${item.product.product_name.substring(
                                0,
                                22
                              )}...`}
                        </Link>
                      </h6>
                      <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                        <span className="px-1">
                          {item.product.discount_amount !== null
                            ? `GH₵(${item.product.discount_amount.substring(
                                0,
                                6
                              )})`
                            : null}
                        </span>
                        <del className="px-1" style={{ color: "red" }}>
                          GH₵ {item.product.price}
                        </del>
                        <span className="px-1">
                          {item.discount === null
                            ? null
                            : `(${item.product.discount}%)`}
                        </span>
                      </p>
                      <Link
                        to={{
                          pathname: `/Company`,
                          state: {
                            data: item.company.company_uuid,
                          },
                        }}
                        className="text-decoration-none px-1 d-flex justify-content-start">
                        {item.company.company_name.length < 20
                          ? `${item.company.company_name}`
                          : `${item.company.company_name.substring(0, 19)}..`}
                      </Link>
                      <hr />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}

        <br />
      </section>
    </div>
  );
};

export default TrendingDashboard;
