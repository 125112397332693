import React, { useState, useEffect } from "react";
import axios from "axios";

const CompanyFilter = () => {
  const [token, setToken] = useState(null);

  useEffect(() => {
    axios.post(
      `${process.env.REACT_APP_Base_url}/shopper/filter_company/`,
      {
        // "industry_id" :
      },
      {
        // "X-SHOPPER-USER-AUTH": token,
        "x-user-pass-auth":
          "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
      }
    );
  }, [token]);

  return (
    <div>
      <li className="list-inline-item">
        <select
          className="selectpicker ml-auto"
          name="sorting"
          data-width={200}
          data-style="bs-select-form-control"
          data-title="Default sorting">
          <option>Select</option>
          <option>Fashion/Textile &amp; Print</option>
          <option>Beautician </option>
          <option>Insurance/Brokerage</option>
          <option>Farming/Forestry</option>
          <option>Legal/Consultancies</option>
          <option>Travel / Tourism</option>
          <option>Pharmaceutical/Biotech/Chemicals</option>
          <option>Construction/Machinery</option>
          <option>Repairs and Restoration</option>
          <option>Social Services/Healthcare services</option>
          <option>Staffing/Recruiting services</option>
          <option>Mining /Construction/Machinery</option>
          <option>Government/Public Administration</option>
          <option>Manufacturing</option>
          <option>Real estate/Building</option>
          <option>Publication/Printing/Media</option>
          <option>Shipping/Transport/Logistic</option>
          <option>Hotel/Catering/Hospitality</option>
          <option>Telecommunication/ IT</option>
          <option>Furniture/ Office Equip./Handwork</option>
          <option>Other services</option>
          <option>Education/Lecturing/teaching</option>
          <option>Entertainment/ TV/Radio</option>
          <option>Wholesales/Trading</option>
          <option>Financial Institution/Banking</option>
          <option>Other industries </option>
        </select>
      </li>
    </div>
  );
};

export default CompanyFilter;
