import React, {  useState, useEffect }from 'react';
import axios from 'axios';

const Enquiries = (props) => {

    console.log(props)


    const [first_name, setFirstName] = useState('')
    const [last_name, setLastName] = useState('')
    const [phone_number, setPhoneNumber] = useState('')
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')
    const [medium, setMedium] = useState('')
    const [company, setCompany] = useState('')
    const [position, setPosition] = useState('')
    const [company_id, setCompanyId] = useState('')

    useEffect(() => {
        setCompanyId(props.data.companyDetails.company_uuid)
    
    },[props.data.companyDetails.company_uuid])


    const handleSubmit = (e) => {
        e.preventDefault()

        axios
          .post(
            `${process.env.REACT_APP_Base_url}/contact-company/add_enquiry/`,
            {
              first_name,
              last_name,
              phone_number,
              email,
              message,
              medium,
              company,
              position,
              company_id: company_id,
            },
            {
              headers: {
                "x-user-pass-auth":
                  "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
                //   'X-SHOPPER-USER-AUTH': token
              },
            }
          )
          .then((res) => {
            console.log(res.data);
            alert("Message Sent Successfully");
            window.location.replace("/Company");
          })
          .catch((err) => {
            console.log(err);
          });
    }

  return (
    <>
                                 
    <div>
        {/* Button trigger modal */}
        <button type="button" className="btn btn-sm btn-primary" data-toggle="modal" data-target="#exampleModal" style={{ borderRadius: "5px" }}>
            Make Enquiries
        </button>
        {/* Modal */}
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">Contact Form</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">×</span>
                </button>
              </div>
              <div className="modal-body">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label >First Name</label>
                    <input type="text" className="form-control" onChange={e => setFirstName(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label >Last Name</label>
                    <input type="text" className="form-control" onChange={e => setLastName(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label >Phone Number</label>
                    <input type="tel" className="form-control" onChange={e => setPhoneNumber(e.target.value)} />
                  </div>

                  <div className="form-group">
                    <label >Email address</label>
                    <input type="email" className="form-control" onChange={e => setEmail(e.target.value)} />
                  </div>

                  <div className="form-group">
                      <label >Message</label>
                      <textarea className="form-control" onChange={e => setMessage(e.target.value)}></textarea>
                  </div>

                  <div className="form-group">

                    <label>Which medium would you like to be contacted via?</label><br />

                    
                   <div className="form-group row pt-2">
                        <div className="col-12">
                        <select name="delivery" value={medium} className="form-control mb-3" onChange={e => setMedium( e.target.value) } >
                            <option>---</option>
                            <option>Phone</option>
                            <option>Email </option>
                        </select>
                        </div>
                    </div>

                  </div>

                  <hr style={{borderBottom: '1px solid black'}} />

                  <div className="" >

                    <div className="form-group">
                      <label >To be completed by companies</label><br />
                      <label >Company</label>
                      <input type="text" className="form-control" onChange={e => setCompany(e.target.value)} />
                    </div>

                    <div className="form-group">
                      <label >Position</label>
                      <input type="text" className="form-control" onChange={e => setPosition(e.target.value)} />
                    </div>

                    
                  </div>

                  <button type="submit" className="btn btn-primary">Submit</button>
                </form>

              </div>
              {/* <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div> */}
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default Enquiries