import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import axios from "axios";
import { FaCartArrowDown, FaUserAlt } from "react-icons/fa";

const Search = () => {
  const [token, setToken] = useState(null);
  const [cart, setCart] = useState([]);
  const [name, setName] = useState("");
  const [record, setRecord] = useState("");
  let history = useHistory();

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(`${process.env.REACT_APP_Base_url}/cart/shopper_cart/`, {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setCart(response.data.shopper_cart_data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/product/advance_search/",
        {
          product_name: name,
          record_name: record,
          offset: "0",
          record_number: "50",
        },
        {
          headers: {
            // "X-SHOPPER-USER-AUTH": token,
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
        history.push({
          pathname: "/DisplaySearch",
          data: { facts: res.data.products },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="header_main">
      <div className="container">
        <div className="row">
          {/* Logo */}
          <div className="col-lg-4 col-sm-3 col-3 order-1">
            <div className="logo_container">
              <div className="logo">
                <a href="/">RosmallOnline</a>
              </div>
            </div>
          </div>{" "}
          {/* Search */}
          <div className="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
            <div className="header_search">
              <div className="header_search_content">
                <div className="header_search_form_container">
                  <form
                    onSubmit={handleSubmit}
                    className="header_search_form clearfix">
                    <input
                      type="search"
                      className="header_search_input"
                      placeholder="Search for products..."
                      onChange={(e) => setName(e.target.value)}
                    />
                    <div
                      className="custom_dropdown"
                      style={{ display: "block" }}>
                      <div className="custom_dropdown_list">
                        <select
                          className="custom_dropdown_placeholder"
                          id="gat"
                          onChange={(e) => setRecord(e.target.value)}>
                          <option> --- </option>
                          <option value="product">Product</option>
                          <option value="service">Service</option>
                        </select>
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>{" "}
                    <button
                      type="submit"
                      className="header_search_button trans_300"
                      value="Submit">
                      <img
                        src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560918770/search.png"
                        alt=""
                      />
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* Wishlist */}
          {!token ? (
            <div className="col-lg-2 col-9 order-lg-3 order-2 text-lg-left text-right">
              <div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
                <div className="cart">
                  <div className="cart_container d-flex flex-row align-items-center justify-content-end">
                    <div className="cart_icon">
                      <Link to="/error" style={{ color: "#0e8ce4" }}>
                        {" "}
                        <FaCartArrowDown size={42} className="px-1" />{" "}
                      </Link>
                    </div>
                    <div className="cart_content px-2">
                      <div className="cart_icon">
                        <Link to="/error" style={{ color: "#0e8ce4" }}>
                          {" "}
                          <FaUserAlt size={42} className="px-1" />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-lg-2 col-9 order-lg-3 order-2 text-lg-left text-right">
              <div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
                <div className="cart">
                  <div className="cart_container d-flex flex-row align-items-center justify-content-end">
                    <div className="cart_icon">
                      <a href="/ProfileCart" style={{ color: "#0e8ce4" }}>
                        <FaCartArrowDown size={42} className="px-1" />
                      </a>
                      {cart && (
                        <div className="cart_count">
                          <span>{cart.length}</span>
                        </div>
                      )}
                    </div>
                    <div className="cart_content px-2">
                      <div className="cart_icon">
                        <Link to="/Profile" style={{ color: "#0e8ce4" }}>
                          {" "}
                          <FaUserAlt size={42} className="px-1" />{" "}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Search;
