import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import ServicesReview from "../Reviews/ServicesReview";
import CompanyServices from "../Related  Services/CompanyServices";
import RelatedServices from "../Related  Services/RelatedServices";
import ServiceEnquiries from "../Enquiries/ServiceEnquiries";

const ServicesDetails = (props) => {
  const [details, setDetails] = useState({ serviceDetails: null });
  const [token, setToken] = useState("");
  const [id, setId] = useState("");

  useEffect(() => {
    setId(props.location.state.data);
    const tk = sessionStorage.getItem("token");
    setToken(tk);
  }, [props.location.state.data]);

  useEffect(() => {
    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/shopper/get_service_details/",
        {
          service_id: id,
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            // 'X-SHOPPER-USER-AUTH': token
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDetails((details) => {
          return { ...details, serviceDetails: res.data.service_details };
        });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, token]);

  return (
    <div className="page-holder bg-light">
      <Header />

      {/* search bar for small screens */}
      <div className="input-group flex-nowrap py-2 px-3" id="sb">
        <input
          type="text"
          className="form-control"
          placeholder="Search anything ..."
          aria-label="Username"
          aria-describedby="basic-addon1"
          style={{ borderRadius: "10px" }}
        />
      </div>
      {/*End of search bar for small screens */}

      <section className="py-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              {/* PRODUCT SLIDER*/}
              {details.serviceDetails && (
                <div className="row m-sm-0">
                  <div className="col-sm-10 order-1 order-sm-2">
                    <div
                      className="owl-carousel product-slider"
                      data-slider-id={1}>
                      <a
                        className="d-block"
                        href={details.serviceDetails.attached_image}
                        data-lightbox="product"
                        title="Product item 1">
                        <img
                          className="img-fluid"
                          src={details.serviceDetails.attached_image}
                          alt="..."
                        />
                      </a>
                    </div>
                    <a
                      className="d-block"
                      href={details.serviceDetails.attached_image}>
                      <img
                        className="img-fluid"
                        src={details.serviceDetails.attached_image}
                        alt="..."
                      />
                    </a>
                  </div>
                </div>
              )}
            </div>
            {/* PRODUCT DETAILS*/}
            {details.serviceDetails && (
              <div className="col-lg-6">
                <Link
                  to={{
                    pathname: `/Company`,
                    state: {
                      data: details.serviceDetails.company.company_uuid,
                    },
                  }}>
                  <h2>{details.serviceDetails.company.company_name}</h2>
                </Link>
                <hr />

                <h3>{details.serviceDetails.service_name}</h3>
                <div className="row align-items-stretch mb-4"></div>
                <br />
                <ul className="list-unstyled small d-inline-block">
                  <li className="px-3 py-2 mb-1 bg-white text-muted">
                    <strong className="text-uppercase text-dark">
                      Category:
                    </strong>
                    <Link className="reset-anchor ml-2" to="#">
                      {details.serviceDetails.category.service_category_name}
                    </Link>
                  </li>
                  <li className="px-3 py-2 mb-1 bg-white text-muted ">
                    <strong className="text-uppercase text-dark">
                      <Link
                        target="_blank"
                        to={
                          details.serviceDetails.company.social_media.instagram
                        }
                        style={{ borderBottom: "none" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}>
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M13 9h4.5l-.5 2h-4v9h-2v-9H7V9h4V7.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 0 1 1.512-1.512C13.698 2.186 14.345 2 16.128 2c.522 0 .98.05 1.372.15V4h-1.372c-1.324 0-1.727.078-2.138.298-.304.162-.53.388-.692.692-.22.411-.298.814-.298 2.138V9z" />
                        </svg>
                      </Link>

                      <Link
                        target="_blank"
                        to={details.serviceDetails.company.social_media.twitter}
                        style={{ borderBottom: "none" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}>
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z" />
                        </svg>
                      </Link>

                      <Link
                        target="_blank"
                        to={
                          details.serviceDetails.company.social_media.instagram
                        }
                        style={{ borderBottom: "none" }}>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          viewBox="0 0 24 24"
                          width={24}
                          height={24}>
                          <path fill="none" d="M0 0h24v24H0z" />
                          <path d="M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.028 1.575a.6.6 0 0 1-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.16 15.7-.329 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538z" />
                        </svg>
                      </Link>
                    </strong>
                  </li>
                  <br />
                  <li className="py-4"></li>

                  <br />
                  <li>
                    <>
                      <>
                        <ServiceEnquiries data={details} />
                      </>
                    </>
                  </li>
                  <br />
                </ul>
              </div>
            )}
          </div>
          {/* DETAILS TABS*/}
          <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="description-tab"
                data-toggle="tab"
                href="#description"
                role="tab"
                aria-controls="description"
                aria-selected="true">
                Description
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link"
                id="reviews-tab"
                data-toggle="tab"
                href="#reviews"
                role="tab"
                aria-controls="reviews"
                aria-selected="false">
                Reviews
              </a>
            </li>
          </ul>

          {details.serviceDetails && (
            <div className="tab-content mb-5" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="description"
                role="tabpanel"
                aria-labelledby="description-tab">
                <div className="p-4 p-lg-5 bg-white">
                  <h6 className="text-uppercase">Services description </h6>
                  <p className="text-muted text-small mb-0">
                    {details.serviceDetails.description}
                  </p>
                </div>
              </div>
              <div
                className="tab-pane fade"
                id="reviews"
                role="tabpanel"
                aria-labelledby="reviews-tab">
                <ServicesReview details={details} />
              </div>
            </div>
          )}

          {/* COMPANY SERVICES*/}
          {details.serviceDetails && <CompanyServices data={details} />}

          {/* RELATED SERVICES*/}
          {details.serviceDetails && <RelatedServices data={details} />}
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default ServicesDetails;
