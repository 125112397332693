import axios from 'axios';
import React, { useState, useEffect} from 'react';
import CompanyServices from '../Related  Services/CompanyServices';
import Footer from '../Views/Footer'
import Header from '../Views/Header';
import "./Company.css"
import CompanyItems from './CompanyItems';
import No_Logo from '../img/no_logo.jpeg'
import Enquiries from '../Enquiries/Enquiries';






 const Company = (props) => {

console.log(props)

    const [details, setDetails] = useState({ companyDetails: null });
    const [review, setReview] = useState({ companyReview: null });
    const [token, setToken] = useState("")
    const [id , setId] = useState("")
    const [visible, setVisible] = useState(3)

    useEffect(() => {
        setId(props.location.state.data)
        const tk = sessionStorage.getItem('token');
        setToken(tk)
    },[ props.location.state.data])



  useEffect(() => {
    axios.post('https://rosmallonline.com:8000/ecoms-api/shopper/view_company_details/',
    {
      "company_id": id,
    }, {
      headers: {
          // 'X-SHOPPER-USER-AUTH': token
          'x-user-pass-auth': "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token"
      }
  }).then((res) => {
      console.log(res.data);
      setDetails(details=>{return{...details, companyDetails: res.data.company_details }})
    }).catch((err) => {
      console.log(err);
    })


    axios.post(`${process.env.REACT_APP_Base_url}/shopper/list_company_reviews/`,
        {
            "company_id" : id,
            "record_number" : "" 
        },
        {
            headers: {
            // 'X-SHOPPER-USER-AUTH': token
          'x-user-pass-auth': "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token"
            }
        })
        .then((res) => {
        console.log(res.data);
        setReview(review=>{return{...review, companyReview: res.data }})
        }).catch((err) => {
        console.log(err);
        });


  },[id, token])

  const showMore = () => {
    setVisible((prevValue) => prevValue + 3)
  };






  
        return (
            <div className="page-holder bg-light">
                <Header />

                <hr />
                <section className="py-1">
                  <div className="container">
                    <div className="row mb-5">
                      <div className="col-lg-12">
                        {/* PRODUCT SLIDER*/}
                       

                        {
                          details.companyDetails &&

                          <div className="row pt-5 m-auto">
                          <div className="col-sm-6 col-md-6 col-lg-6">    
                            <div className="card card-custom bg-white border-white border-0">
                              <div className="card-custom-img" style={{backgroundImage: 'url("https://images.unsplash.com/photo-1460602594182-8568137446ce?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1355&q=80")'}} />
                              <div className="card-custom-avatar">
                                {(details.companyDetails.company_logo === "No Logo" ) ? 
                                    <img className="img-fluid" src={No_Logo} alt="company logo" /> 
                                  :
                                    <img className="img-fluid" src={details.companyDetails.company_logo} alt="company logo" /> 
                                }
                              </div>
                              <div className="card-body" style={{overflowY: 'auto'}}>
                                <h4 className="card-title">
                                  {details.companyDetails.company_name}
                                </h4>
                                <p>Website -<a href={details.companyDetails.website} target="_blank" rel="noreferrer"> {details.companyDetails.website}</a></p>
                                <p>GPS -<span> {details.companyDetails.gps}</span></p>
                                <div>
                                  <a href={details.companyDetails.social_media.facebook} target="_blank" rel="noreferrer" style={{borderBottom: 'none'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}><path fill="none" d="M0 0h24v24H0z" /><path d="M13 9h4.5l-.5 2h-4v9h-2v-9H7V9h4V7.128c0-1.783.186-2.43.534-3.082a3.635 3.635 0 0 1 1.512-1.512C13.698 2.186 14.345 2 16.128 2c.522 0 .98.05 1.372.15V4h-1.372c-1.324 0-1.727.078-2.138.298-.304.162-.53.388-.692.692-.22.411-.298.814-.298 2.138V9z" /></svg>
                                  </a>
                                  <a href={details.companyDetails.social_media.instagram} target="_blank" rel="noreferrer" style={{borderBottom: 'none'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}><path fill="none" d="M0 0h24v24H0z" /><path d="M12 9a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm0-2a5 5 0 1 1 0 10 5 5 0 0 1 0-10zm6.5-.25a1.25 1.25 0 0 1-2.5 0 1.25 1.25 0 0 1 2.5 0zM12 4c-2.474 0-2.878.007-4.029.058-.784.037-1.31.142-1.798.332-.434.168-.747.369-1.08.703a2.89 2.89 0 0 0-.704 1.08c-.19.49-.295 1.015-.331 1.798C4.006 9.075 4 9.461 4 12c0 2.474.007 2.878.058 4.029.037.783.142 1.31.331 1.797.17.435.37.748.702 1.08.337.336.65.537 1.08.703.494.191 1.02.297 1.8.333C9.075 19.994 9.461 20 12 20c2.474 0 2.878-.007 4.029-.058.782-.037 1.309-.142 1.797-.331.433-.169.748-.37 1.08-.702.337-.337.538-.65.704-1.08.19-.493.296-1.02.332-1.8.052-1.104.058-1.49.058-4.029 0-2.474-.007-2.878-.058-4.029-.037-.782-.142-1.31-.332-1.798a2.911 2.911 0 0 0-.703-1.08 2.884 2.884 0 0 0-1.08-.704c-.49-.19-1.016-.295-1.798-.331C14.925 4.006 14.539 4 12 4zm0-2c2.717 0 3.056.01 4.122.06 1.065.05 1.79.217 2.428.465.66.254 1.216.598 1.772 1.153a4.908 4.908 0 0 1 1.153 1.772c.247.637.415 1.363.465 2.428.047 1.066.06 1.405.06 4.122 0 2.717-.01 3.056-.06 4.122-.05 1.065-.218 1.79-.465 2.428a4.883 4.883 0 0 1-1.153 1.772 4.915 4.915 0 0 1-1.772 1.153c-.637.247-1.363.415-2.428.465-1.066.047-1.405.06-4.122.06-2.717 0-3.056-.01-4.122-.06-1.065-.05-1.79-.218-2.428-.465a4.89 4.89 0 0 1-1.772-1.153 4.904 4.904 0 0 1-1.153-1.772c-.248-.637-.415-1.363-.465-2.428C2.013 15.056 2 14.717 2 12c0-2.717.01-3.056.06-4.122.05-1.066.217-1.79.465-2.428a4.88 4.88 0 0 1 1.153-1.772A4.897 4.897 0 0 1 5.45 2.525c.638-.248 1.362-.415 2.428-.465C8.944 2.013 9.283 2 12 2z" /></svg>
                                  </a>
                                  <a href={details.companyDetails.social_media.twitter} target="_blank" rel="noreferrer" style={{borderBottom: 'none'}}>
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={24} height={24}><path fill="none" d="M0 0h24v24H0z" /><path d="M15.3 5.55a2.9 2.9 0 0 0-2.9 2.847l-.028 1.575a.6.6 0 0 1-.68.583l-1.561-.212c-2.054-.28-4.022-1.226-5.91-2.799-.598 3.31.57 5.603 3.383 7.372l1.747 1.098a.6.6 0 0 1 .034.993L7.793 18.17c.947.059 1.846.017 2.592-.131 4.718-.942 7.855-4.492 7.855-10.348 0-.478-1.012-2.141-2.94-2.141zm-4.9 2.81a4.9 4.9 0 0 1 8.385-3.355c.711-.005 1.316.175 2.669-.645-.335 1.64-.5 2.352-1.214 3.331 0 7.642-4.697 11.358-9.463 12.309-3.268.652-8.02-.419-9.382-1.841.694-.054 3.514-.357 5.144-1.55C5.16 15.7-.329 12.47 3.278 3.786c1.693 1.977 3.41 3.323 5.15 4.037 1.158.475 1.442.465 1.973.538z" /></svg>
                                  </a>

                                  <section className="my-2">
                                    <Enquiries data={details} />
                                  </section>

                                </div>  
                              </div> 
                            </div>
                          </div>
                          <div className="col-sm-6 col-md-6 col-lg-6">
                            <div className="card card-custom bg-white border-white border-0">
                            <div dangerouslySetInnerHTML={{ __html: `${details.companyDetails.google_map_location}`}} />

                            </div>
                          </div>
                        </div>

                        }

                      </div>
                          
                      {/* COMPANY BACKGROUND */}
                        <div className="col-12 pt-4">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="card">
                                  <div className="body">
                                    <div className="cd-horizontal-timeline loaded">
                                      <div className="container">
                                          <div>
                                              <h5 className="text-uppercase m-2">About Company </h5><hr />
                                          </div>
                                      </div>

                                      <div className="container">
                                        <div className="row">
                                            <div className="col-sm-12">
                                              <section className="">
                                                    {
                                                      details.companyDetails && 
                                                      <p>
                                                      {details.companyDetails.background}
                                                    </p>
                                                    }
                                                </section>
                                            </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      {/*END OF COMPANY BACKGROUND */}

                      

                      {/* REVIEW COMMENTS */}
                      {review.companyReview &&
                        <div className="col-12 py-5">
                          <div className="container">
                            <div className="row">
                              <div className="col-lg-12">
                                <div className="card">
                                  <div className="body">
                                    <div className="cd-horizontal-timeline loaded">
                                    
                                      <div className="container">
                                          <div>
                                              <h5 className="text-uppercase m-2">Customer Comments of Company</h5><hr />
                                          </div>
                                          <div className="row">
                                                {
                                                  review.companyReview.ratings_summary &&
                                                  <div className="col-sm-4">
                                                    <section className="">
                                                        <h5 className="pb-2">COMPANY RATING ({review.companyReview.ratings_summary.length})</h5>
                                                        <h5 className="py-2"> {review.companyReview.company_rating.slice(0,3)} / 5 </h5>
                                                        <ul className="list-inline mb-2 py-2">
                                                            <li className="list-inline-item m-0"><i className="fas fa-star fa-1x small text-warning" /></li>
                                                            <li className="list-inline-item m-0"><i className="fas fa-star fa-1x small text-warning" /></li>
                                                            <li className="list-inline-item m-0"><i className="fas fa-star fa-1x small text-warning" /></li>
                                                            <li className="list-inline-item m-0"><i className="fas fa-star fa-1x small text-warning" /></li>
                                                            <li className="list-inline-item m-0"><i className="fas fa-star fa-1x small text-warning" /></li>
                                                        </ul>
                                                        <h5 className="py-5">{review.companyReview.reviews.length} Ratings Received</h5>
                                                    </section>

                                                    <section className="">
                                                      {
                                                        review.companyReview.ratings_summary.map((rating) =>{
                                                          return (
                                                              <p key={rating.rate}>
                                                                  <span className="px-1">{rating.rate}</span>
                                                                  <span className="px-1"><i className="fas fa-star fa-1x small text-warning" />
                                                                  </span> <span className="px-1">({rating.number_of_ratings})</span>
                                                                  <span className="progress">
                                                                      <span className="progress-bar" role="progressbar" style={{ width: `${rating.percentage}%` }} aria-valuenow={rating.number_of_ratings} aria-valuemin={0} aria-valuemax={100}>
                                                                      </span></span>
                                                              </p>
                                                          )
                                                        })
                                                      }
                                                     
                                                  </section>
                                                </div>
                                                }
                                                            

                                                    {
                                                      review.companyReview.reviews &&
                                                      <div className="col-sm-8">
                                                        <div className="media-body">
                                                          <h5 className="mt-0 pb-2">COMPANY REVIEWS ({review.companyReview.reviews.length})</h5>
                                                          
                                                          {review.companyReview.reviews.slice(0,visible).map((item)=> {
                                                            return (
                                                                <section className="list-inline" key={item.review_id}>
                                                                 <h5>{item.rating} <li className="list-inline-item m-0"><i className="fas fa-star fa-1x small text-warning" /></li></h5>
                                                                  <p>{item.comment}</p>
                                                                  <p>{item.date_created} <span className="px-4">by {item.shopper.first_name}</span></p>
                                                              </section>
                                                            )
                                                          })
                                                          
                                                          }
                                                              <button className="btn btn-primary" onClick={showMore} >Load More</button>
                                                         
                                                        </div>
                                                    </div>
                                                    }
                                            
                                          </div>
                                      
                                      </div>

                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        }
                      {/*END OF TIMELINE EVENTS */}

                      {details.companyDetails && 
                          <div className="container">
                          {/* <CompanyServices data={details} /> */}
                          <CompanyItems data={details.companyDetails} />
                        </div>
                      }

                    </div>
                  </div>
                </section>
                
                <Footer />
            </div>

        );
    }


export default Company;