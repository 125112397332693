import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import Add from "../img/new rosmall.png";
import Ad from "../img/new rosmall 1.png";
import B from "../img/banner 2.png";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Navigation } from "swiper";

const Ads = () => {
  return (
    <div>
      <>
        <Swiper
          slidesPerView={2}
          spaceBetween={5}
          autoplay={{
            delay: 2500,
            disableOnInteraction: false,
          }}
          modules={[Autoplay, Navigation]}
          className="mySwiper">
          <SwiperSlide>
            <img className="" src={B} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img className="" src={B} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img className="" src={Ad} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img className="" src={Ad} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img className="" src={Add} alt=".." width="100%" />
          </SwiperSlide>

          <SwiperSlide>
            <img className="" src={Add} alt=".." width="100%" />
          </SwiperSlide>
        </Swiper>
      </>
    </div>
  );
};

export default Ads;
