import React, {  } from 'react'
// import { Link } from 'react-router-dom';
import Footer from '../Views/Footer'
import Header from '../Views/Header';

 const Payment = ()  => {
        return (
            <div className="page-holder">
        
                <Header />
                
                <div className="container">
                  <section className="py-5">
                    <h2 className="h5 text-uppercase mb-4">Payment details</h2>
                    <div className="row">
                      <div className="col-lg-8">
                        
                        <form >
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="firstName2">Bank name</label>
                                <input className="form-control form-control-lg" type="text" placeholder="Enter bank name" />
                              </div>
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="account_name">Account name</label>
                                <input className="form-control form-control-lg" type="text" placeholder="Solomon Abrempong" />
                              </div>
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="phone2">Reference number</label>
                                <input className="form-control form-control-lg"  type="number" placeholder={" 0092245354745 "} />
                              </div>
                              
                              <div className="col-lg-6 form-group">
                                <label className="text-small text-uppercase" htmlFor="address2">Branch</label>
                                <input className="form-control form-control-lg"  type="text" placeholder="House number and street name" />
                              </div>
                            </div>
                          </div>
                        </form>

                        <div className="container-fluid py-5">
                          <div className="col-12 py-5">
                            <h2 className="h4 text-uppercase mb-4">Alternative billing details here</h2><hr />
                          </div>
                          <div className="row">
                            <div className="col">
                              <img src="./img/airteltigo.png" alt="airtelTigo" className="img-fluid" />
                              <section className="py-2">
                                <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal" style={{borderColor: 'white !important'}}>
                                  <i className="fa fa-address-card" aria-hidden="true" /><span className="pl-1">AirtelTigo</span>
                                </button>
                                {/* Modal */}
                                <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel">AirtelTigo payment details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div >
                                          <p>Reference # : <span className="pl-5">2346908125</span></p>
                                        </div>
                                        <div >
                                          <p>Momo # : <span className="pl-5">0579846573</span></p>
                                        </div>
                                        <div >
                                          <p>Account Name : <span className="pl-5">Abrempong Solomon</span></p>
                                        </div>
                                        <div >
                                          <p>Total cost # : <span className="pl-5">$60</span></p>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">close</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="col">
                              <img src="./img/mtn.png" alt="mtn" className="img-fluid" />
                              <section className="py-2">
                                {/* Button trigger modal */}
                                <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal2" style={{borderColor: 'white !important'}}>
                                  <i className="fa fa-address-card" aria-hidden="true" /><span className="pl-1">MTN</span>
                                </button>
                                {/* Modal */}
                                <div className="modal fade" id="exampleModal2" tabIndex={-1} aria-labelledby="exampleModalLabel2" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel2">MTN payment Details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div>
                                          <p>Reference # : <span className="pl-5">2346908125</span></p>
                                        </div>
                                        <div>
                                          <p>Momo # : <span className="pl-5">0559846573</span></p>
                                        </div>
                                        <div>
                                          <p>Account Name : <span className="pl-5">Abrempong Solomon</span></p>
                                        </div>
                                        <div>
                                          <p>Total cost # : <span className="pl-5">$60</span></p>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">close</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                            <div className="col">
                              <img src="./img/voda.png" alt="vodafone" className="img-fluid" />
                              <section className="py-2">
                                {/* Button trigger modal */}
                                <button type="button" className="btn btn-outline-primary" data-toggle="modal" data-target="#exampleModal3" style={{borderColor: 'white !important'}}>
                                  <i className="fa fa-address-card" aria-hidden="true" /><span className="pl-1">Vodafone</span>
                                </button>
                                {/* Modal */}
                                <div className="modal fade" id="exampleModal3" tabIndex={-1} aria-labelledby="exampleModalLabel3" aria-hidden="true">
                                  <div className="modal-dialog">
                                    <div className="modal-content">
                                      <div className="modal-header">
                                        <h5 className="modal-title" id="exampleModalLabel3">Vodafone payment Details</h5>
                                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                                          <span aria-hidden="true">×</span>
                                        </button>
                                      </div>
                                      <div className="modal-body">
                                        <div>
                                          <p>Reference # : <span className="pl-5">2346908125</span></p>
                                        </div>
                                        <div>
                                          <p>Momo # : <span className="pl-5">0209846573</span></p>
                                        </div>
                                        <div>
                                          <p>Account Name : <span className="pl-5">Abrempong Solomon</span></p>
                                        </div>
                                        <div>
                                          <p>Total cost # : <span className="pl-5">$60</span></p>
                                        </div>
                                      </div>
                                      <div className="modal-footer">
                                        <button type="button" className="btn btn-secondary" data-dismiss="modal">close</button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                        {/*END OF PAYMENT SECTION */}
                      </div>
                      {/* ORDER SUMMARY*/}
                      <div className="col-lg-4">
                        <div className="card border-0 rounded-0 p-lg-4 bg-light">
                          <div className="card-body">
                            <h5 className="text-uppercase mb-4">Your order</h5>
                            <ul className="list-unstyled mb-0">
                              <li className="d-flex align-items-center justify-content-between"><strong className="small font-weight-bold">Red digital smartwatch</strong><span className="text-muted small">$250</span></li>
                              <li className="border-bottom my-2" />
                              <li className="d-flex align-items-center justify-content-between"><strong className="small font-weight-bold">Gray Nike running shoes</strong><span className="text-muted small">$351</span></li>
                              <li className="border-bottom my-2" />
                              <li className="d-flex align-items-center justify-content-between"><strong className="text-uppercase small font-weight-bold">Total</strong><span>$601</span></li>
                              <div className="container-fluid py-5">
                                <h5 className="text-uppercase mb-4"> Payment Channels</h5>
                                <img src="./img/alll.png" className="img-fluid" alt="all" />
                                <ul className="list-unstyled mb-0 py-3">
                                  <li className="border-bottom my-2" />
                                  <li className="d-flex align-items-center justify-content-between"><strong className="text-uppercase small font-weight-bold">Bank Transfer</strong><span /></li>
                                  <li className="border-bottom my-2" />
                                </ul>
                              </div>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>

                <Footer />            
            
            </div>

        );
    }

export default Payment;
