import React, { useEffect } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import Ads from "./Ads";
import CompaniesDashboard from "./CompaniesDashboard";
import ServicesDashboard from "./ServicesDashboard";
import SlidersDashboard from "./SlidersDashboard";
import TrendingDashboard from "./TrendingDashboard";
import Scroll from "./Scroll/Scroll";

const Home = () => {
  useEffect(() => {}, []);

  return (
    <div className="page-holder">
      <Header />

      <div className="input-group flex-nowrap py-2 px-3" id="sb">
        <a
          href="/AdvanceSearch"
          type="button"
          className="btn btn-primary form-control text-white"
          role="button"
          style={{ backgroundColor: "#0e8ce4" }}>
          Advance Search
        </a>
      </div>

      <div className="container-fluid">
        {/* IMAGE SLIDDER */}
        <SlidersDashboard />
        {/* IMAGE SLIDDER */}

        <Scroll />

        <div className="container">
          {/* TRENDING PRODUCTS*/}
          <TrendingDashboard />
          {/* TRENDING PRODUCTS */}

          {/* GOOGLE ADS */}
          <Ads />
          {/* GOOGLE ADS */}

          {/* SERVICES*/}
          <ServicesDashboard />
          {/* SERVICES */}

          {/* GOOGLE ADS */}
          <Ads />
          {/* GOOGLE ADS */}

          {/* COMPANIES  */}
          <CompaniesDashboard />
          {/* END OF COMPANIES */}

          {/* Banners*/}
          <section className="py-3">
            <div className="container">
              <div className="row text-center">
                <div className="col-lg-3 col-6">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <div className="media-body">
                        <img src="./img/sss1.png" alt="..." />
                        <br />
                        <h6 className="text-uppercase text-center py-2">
                          Top Rated Sellers
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <div className="media-body">
                        <img src="./img/sss5.png" alt="..." />
                        <br />
                        <h6 className="text-uppercase text-center py-2">
                          Super Deals
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <div className="media-body">
                        <img src="./img/sss4.png" alt="..." />
                        <br />
                        <h6 className="text-uppercase text-center py-2">
                          Quality service Assured{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-6">
                  <div className="d-inline-block">
                    <div className="media align-items-end">
                      <div className="media-body">
                        <img src="./img/sss6.png" alt="..." />
                        <br />
                        <h6 className="text-uppercase text-center py-2">
                          Money Back Gurranted
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <hr />
          {/* Banners*/}

          {/* NEWSLETTER*/}
          <section className="py-5">
            <div className="container p-0">
              <div className="row">
                <div className="col-lg-6 mb-3 mb-lg-0">
                  <h5 className="text-uppercase">Let's be friends!</h5>
                  <p className="text-small text-muted mb-0">
                    Subscribe to our news letter to get the latest and hottest
                    deals on our site at anytime.
                  </p>
                </div>
                <div className="col-lg-6">
                  <form action="#">
                    <div className="input-group flex-column flex-sm-row mb-3">
                      <input
                        className="form-control form-control-lg py-3"
                        type="email"
                        placeholder="Enter your email address"
                        aria-describedby="button-addon2"
                      />
                      <div className="input-group-append">
                        <button
                          className="btn btn-dark btn-block"
                          id="button-addon2"
                          type="submit">
                          Subscribe
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Home;
