import React, { useState, useEffect } from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";
import axios from "axios";
import { Link } from "react-router-dom";

const CartDetails = (props) => {
  console.log(props);

  const [cartDetails, setCartDetails] = useState({ cartDetail: null });
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    setId(props.location.state.data);

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/cart/view_cart_detail/`,
        {
          cart_id: id,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((response) => {
        console.log(response.data);
        setCartDetails((cartDetails) => {
          return { ...cartDetails, cartDetail: response.data.cart_detail };
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [id, props.location.state.data, token]);

  return (
    <div className="page-holder bg-light">
      <Header /> <hr />
      <section className="py-5">
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              {/* PRODUCT SLIDER*/}
              <div className="row m-sm-0">
                {/* <div className="col-sm-2 p-sm-0 order-2 order-sm-1 mt-2 mt-sm-0">
                        <div className="owl-thumbs d-flex flex-row flex-sm-column" data-slider-id={1}>
                          <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0"><img className="w-100" src="../img/product-detail-1.jpg" alt="..." /></div>
                          <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0"><img className="w-100" src="../img/product-detail-2.jpg" alt="..." /></div>
                          <div className="owl-thumb-item flex-fill mb-2 mr-2 mr-sm-0"><img className="w-100" src="../img/product-detail-3.jpg" alt="..." /></div>
                          <div className="owl-thumb-item flex-fill mb-2"><img className="w-100" src="../img/product-detail-4.jpg" alt="..." /></div>
                        </div>
                      </div> */}
                {/* <div className="col-sm-10 order-1 order-sm-2">
                        <div className="owl-carousel " >
                          <a className="d-block" href="../img/product-detail-2.jpg" data-lightbox="product" title="Product item 2">
                            <img className="img-fluid" src={cartDetails.cartDetail.product.image_url} alt="..." /></a>
                            <a className="d-block" href="../img/product-detail-3.jpg" data-lightbox="product" title="Product item 3"><img className="img-fluid" src="../img/product-detail-3.jpg" alt="..." /></a><a className="d-block" href="../img/product-detail-4.jpg" data-lightbox="product" title="Product item 4"><img className="img-fluid" src="../img/product-detail-4.jpg" alt="..." /></a></div>
                      </div> */}
                {cartDetails.cartDetail && (
                  <div className="col-sm-10 order-1 order-sm-2">
                    <a
                      className="d-block"
                      href={cartDetails.cartDetail.product.image_url}
                      title="Product item 1">
                      <img
                        className="img-fluid"
                        src={cartDetails.cartDetail.product.image_url}
                        alt="..."
                      />
                    </a>
                  </div>
                )}
              </div>
            </div>
            {/* PRODUCT DETAILS*/}
            {cartDetails.cartDetail && (
              <div className="col-lg-6">
                {/* <a href="/Company"><h2>{cartDetails.cartDetail.product.company.company_name}</h2></a><hr /> */}
                <Link
                  to={{
                    pathname: `/Company`,
                    state: {
                      data: cartDetails.cartDetail.product.company.company_uuid,
                    },
                  }}
                  className="ml-auto font-weight-bold"
                  style={{ fontSize: "2.5rem" }}>
                  {" "}
                  {cartDetails.cartDetail.product.company.company_name}{" "}
                </Link>
                <hr />

                <h3>{cartDetails.cartDetail.product_name}</h3>
                <p className="text-muted lead">
                  GH₵{cartDetails.cartDetail.amount}
                </p>
                <div className="row align-items-stretch mb-4">
                  <div className="col-sm-5 pr-sm-0">
                    <div className="border d-flex align-items-center justify-content-between py-1 px-3 bg-white border-white">
                      <span className="small text-uppercase text-gray mr-4 no-select">
                        Quantity
                      </span>
                      <div className="quantity">
                        <span className="form-control border-0 shadow-0 p-2">
                          {cartDetails.cartDetail.quantity}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <br />
                <ul className="list-unstyled small d-inline-block">
                  <li className="px-3 py-2 mb-1 bg-white text-muted">
                    <strong className="text-uppercase text-dark">
                      Category:
                    </strong>
                    <a className="reset-anchor ml-2" href="#/">
                      {
                        cartDetails.cartDetail.product.category
                          .product_category_name
                      }
                    </a>
                  </li>
                  <li className="px-3 py-2 mb-1 bg-white text-muted">
                    <strong className="text-uppercase text-dark">Tags:</strong>
                    <a className="reset-anchor ml-2" href="#/">
                      {cartDetails.cartDetail.product.product_type}
                    </a>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* DETAILS TABS*/}
          <ul className="nav nav-tabs border-0" id="myTab" role="tablist">
            <li className="nav-item">
              <a
                className="nav-link active"
                id="description-tab"
                data-toggle="tab"
                href="#description"
                role="tab"
                aria-controls="description"
                aria-selected="true">
                Description
              </a>
            </li>
          </ul>
          {cartDetails.cartDetail && (
            <div className="tab-content mb-5" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="description"
                role="tabpanel"
                aria-labelledby="description-tab">
                <div className="p-4 p-lg-5 bg-white">
                  <h6 className="text-uppercase">Product description </h6>
                  <p className="text-muted text-small mb-0">
                    {cartDetails.cartDetail.product.description}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default CartDetails;
