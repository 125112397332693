import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Header from "./../Views/Header";
import Footer from "./../Views/Footer";

const DisplaySearch = (props) => {
  console.log(props);
  const [list, setList] = useState([]);

  useEffect(() => {
    if (props) {
      setList(props.location.data.facts);
    }
  }, [props]);

  return (
    <div>
      <Header />

      <div className="container py-5">
        <div className="row">
          {list.map((items) => {
            return (
              <div
                className="col-lg-3 col-md-4 col-sm-6"
                key={items.product_uuid}>
                <div className="">
                  <Link
                    to={{
                      pathname: "/ProductDetails",
                      state: { data: items.product_uuid },
                    }}>
                    <img
                      src={items.image_url}
                      alt="Image1"
                      style={{ maxWidth: "100%" }}
                    />
                  </Link>
                  <h6 className="mb-0">
                    <Link
                      className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                      to={{
                        pathname: `/ProductDetails`,
                        state: { data: items.product_uuid },
                      }}>
                      {items.product_name.length < 25
                        ? `${items.product_name}`
                        : `${items.product_name.substring(0, 23)}...`}
                    </Link>
                  </h6>
                  <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                    <span className="px-1">
                      {" "}
                      {items.discount_amount !== null
                        ? `GH₵(${items.discount_amount})`
                        : `${""}`}
                    </span>
                    <del className="px-1" style={{ color: "red" }}>
                      GH₵ {items.price}
                    </del>
                    <span className="px-1">
                      {" "}
                      {items.discount === ""
                        ? `${""}`
                        : `(${items.discount}%)`}{" "}
                    </span>
                  </p>
                  <Link
                    to={{
                      pathname: `/Company`,
                      state: { data: items.company.company_uuid },
                    }}
                    className="text-decoration-none px-1 d-flex justify-content-start">
                    {items.company.company_name.length < 20
                      ? `${items.company.company_name}`
                      : `${items.company.company_name.substring(0, 19)}..`}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default DisplaySearch;
