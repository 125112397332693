import React, { useState, useEffect } from "react";
import axios from "axios";

const ProductFilter = () => {
  const [cat, setCat] = useState([]);
  const [token, setToken] = useState(null);
  const [product_name, setProduct] = useState("");
  const [product_id, setProductId] = useState("");
  const [from, setfrom] = useState("");
  const [to, setTo] = useState("");
  const [region, setRegion] = useState("");
  // const [condition,setCondition] = useState("")
  // const [delivery,setDelivery] = useState("")
  // const [returnable,setReturnable] = useState("")
  // const [offset,setOffset] = useState("")
  // const [record_number,setRecordNumber] = useState("")
  const [regionList, setRegionList] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/get_regions/`, {
        headers: {
          // "X-SHOPPER-USER-AUTH": token,
          "x-user-pass-auth":
            "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
        },
      })
      .then((response) => {
        console.log(response.data);
        setRegionList(response.data.regions);
      })
      .catch((err) => {
        console.log(err);
      });

    axios
      .get(`${process.env.REACT_APP_Base_url}/setup/list_product_category/`)
      .then((res) => {
        console.log(res.data);
        setCat(res.data.product_category);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/product_filter/`,
        {
          product_name: product_name,
          product_id: product_id,
          price: [from, to],
          region_id: region,
          condition: "",
          delivery: "",
          returnable: "",
          offset: "1",
          record_number: "10",
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            // "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        alert(resp.data.message);
      })
      .catch((err) => {
        console.log(err);
        alert(err);
      });
  };

  return (
    <div className="">
      {/* Button trigger modal */}
      <button
        type="button"
        className="form-control btn btn-primary"
        data-toggle="modal"
        data-target="#exampleModal"
        style={{ backgroundColor: "#0e8ce4", color: "#fff" }}>
        Click here to Filter
      </button>
      {/* Modal */}
      <div
        className="modal fade"
        id="exampleModal"
        tabIndex={-1}
        aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Filter Page
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">
              <div>
                <div className="container px-5">
                  <div class="container py-5">
                    <form onSubmit={handleSubmit}>
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label htmlFor="inputEmail4">
                            Search by Product name
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Search by Product name"
                            onChange={(e) => setProduct(e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        <div className="form-group col-6">
                          <label htmlFor="inputAddress2">
                            Price range from
                          </label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputAddress2"
                            placeholder="Price range from"
                            onChange={(e) => setfrom(e.target.value)}
                          />
                        </div>
                        <div className="form-group col-6">
                          <label htmlFor="inputAddress2">Price range to</label>
                          <input
                            type="number"
                            className="form-control"
                            id="inputAddress2"
                            placeholder="Price range to"
                            onChange={(e) => setTo(e.target.value)}
                          />
                        </div>
                      </div>
                      <hr />

                      {/*                                        
                                        <div className="form-group">
                                            <label htmlFor="inputAddress">Condition</label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="gridCheck"  value={'HomeUsed'} onChange={e => setCondition(e.target.value)} />
                                                <label className="form-check-label" htmlFor="gridCheck">
                                                    Home used
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" id="gridCheck" value={'BrandNew'} onChange={e => setCondition(e.target.value)}/>
                                                <label className="form-check-label" htmlFor="gridCheck">
                                                    Brand new
                                                </label>
                                            </div>
                                        </div>
                                        
                                        <div className="form-group">
                                            <label htmlFor="inputAddress">Is item Returnable</label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={'Yes'} id='Yes' onChange={e => setReturnable(e.target.value)}/>
                                                <label className="form-check-label" htmlFor="Yes">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={'No'} id='No' onChange={e => setReturnable(e.target.value)}/>
                                                <label className="form-check-label" htmlFor="No">
                                                    No
                                                </label>
                                            </div>
                                        </div>


                                        <div className="form-group">
                                            <label htmlFor="inputAddress">Is Delivery available for item </label>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={'Yes'} id='Yes' onChange={e => setDelivery(e.target.value)}/>
                                                <label className="form-check-label" htmlFor="Yes">
                                                    Yes
                                                </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="checkbox" value={'No'} id='No'onChange={e => setDelivery(e.target.value)} />
                                                <label className="form-check-label" htmlFor="No">
                                                    No
                                                </label>
                                            </div>
                                        </div> */}

                      <hr />

                      <div className="col-12">
                        <label htmlFor="inputAddress">Region of Location</label>
                        {regionList && (
                          <div className="form-group  col-12">
                            <select
                              name="account"
                              className="form-control mb-3"
                              style={{ width: "auto" }}
                              onChange={(e) => setRegion(e.target.value)}>
                              <option value="-----" selected>
                                ---
                              </option>
                              {regionList.map((item, key) => {
                                return (
                                  <option value={item.region_id} key={key}>
                                    {item.name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="col-12">
                        <label htmlFor="inputAddress">Products</label>
                        {cat && (
                          <div className="form-group  col-12">
                            <select
                              name="account"
                              className="form-control mb-3"
                              style={{ width: "auto" }}
                              onChange={(e) => setProductId(e.target.value)}>
                              <option value="-----" selected>
                                ---
                              </option>
                              {cat.map((item, key) => {
                                return (
                                  <option
                                    value={item.product_category_id}
                                    key={key}>
                                    {item.product_category_name}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        )}
                      </div>
                      <hr />

                      <div className="modal-footer">
                        <button type="submit" className="btn btn-primary">
                          Search
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFilter;
