import React from "react";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const Refund = () => {
  return (
    <div className="">
      <Header />
      <div className="container px-1">
        <div className="container text-left px-3 py-2 m-2">
          <h2 className="h4 text-bold pt-2">Return and Refund Policy</h2>
          <p>Last updated: January 19, 2022</p>
          <p>Thank you for shopping at RosMallOnline.</p>
          <p>
            If, for any reason, You are not completely satisfied with a purchase
            We invite You to review our policy on refunds and returns. This
            Return and Refund Policy has been created with the help of the
            Return and Refund Policy Generator.
          </p>
          <p>
            The following terms are applicable for any products that You
            purchased with Us
          </p>

          <h2 className="h4 text-bold">Interpretation and Definitions</h2>
          <hr />
          <h4 className="h6 py-2 text-bold">Interpretation</h4>
          <p>
            The words of which the initial letter is capitalized have meanings
            defined under the following conditions. The following definitions
            shall have the same meaning regardless of whether they appear in
            singular or in plural.
          </p>

          <h5 className="h6 text-bold">Definitions</h5>
          <p>For the purposes of this Return and Refund Policy:</p>
          <ul className="list-inline mb-1 text-xs pl-5">
            <li className="nav-item dropdown">
              <p>
                <strong>Company </strong> (referred to as either "the Company",
                "We", "Us" or "Our" in this Agreement) refers to RosMall Company
                Ltd, Brenya Street, House number 15, Apenkwa Tesano, Accra
                Ghana.
              </p>
            </li>
            <li>
              <p>
                <strong>Goods </strong> refer to the items offered for sale on
                the Service.
              </p>
            </li>
            <li>
              <p>
                <strong>Orders </strong> mean a request by You to purchase Goods
                from Us.
              </p>
            </li>
            <li>
              <p>
                <strong>Service </strong> refers to the Website.
              </p>
            </li>
            <li>
              <p>
                <strong>Website </strong> refers to Rosmallonline, accessible
                from www.rosmallonline.com You means the individual accessing or
                using the Service, or the company, or other legal entity on
                behalf of which such individual is accessing or using the
                Service, as applicable.
              </p>
            </li>
          </ul>

          <h5 className="h4 text-bold pt-2">Your Order Cancellation Rights</h5>
          <hr />
          <p>
            You are entitled to cancel Your Order within 30 days without giving
            any reason for doing so. .
          </p>
          <p>
            The deadline for cancelling an Order is 30 days from the date on
            which You received the Goods or on which a third party you have
            appointed, who is not the carrier, takes possession of the product
            delivered.
          </p>
          <p>
            In order to exercise Your right of cancellation, You must inform Us
            of your decision by means of a clear statement. You can inform us of
            your decision by:
          </p>
          <p className="pl-3">
            By visiting this page on our website: www.rosmallonline.com
          </p>
          <p>
            We will reimburse You no later than 14 days from the day on which We
            receive the returned Goods. We will use the same means of payment as
            You used for the Order, and You will not incur any fees for such
            reimbursement.
          </p>

          <h5 className="h4 text-bold pt-2">Conditions for Returns</h5>
          <hr />
          <h4 className="h6 py-2 text-bold">
            In order for the Goods to be eligible for a return, please make sure
            that:
          </h4>
          <p className="px-3">The Goods were purchased in the last 30 days</p>
          <p className="px-3">The Goods are in the original packaging</p>

          <h4 className="h6 py-2 text-bold">
            The following Goods cannot be returned:
          </h4>
          <p className="px-3">
            The supply of Goods made to Your specifications or clearly
            personalized.
          </p>
          <p className="px-3">
            The supply of Goods which according to their nature are not suitable
            to be returned, deteriorate rapidly or where the date of expiry is
            over.
          </p>
          <p className="px-3">
            The supply of Goods which are not suitable for return due to health
            protection or hygiene reasons and were unsealed after delivery
          </p>
          <p className="px-3">
            The supply of Goods which are, after delivery, according to their
            nature, inseparably mixed with other items.
          </p>
          <p>
            We reserve the right to refuse returns of any merchandise that does
            not meet the above return conditions in our sole discretion.
          </p>
          <p>
            Only regular priced Goods may be refunded. Unfortunately, Goods on
            sale cannot be refunded. This exclusion may not apply to You if it
            is not permitted by applicable law
          </p>

          <h4 className="h4 py-2 text-bold">Returning Goods</h4>
          <hr />
          <p>
            You are responsible for the cost and risk of returning the Goods to
            Us. You should send the Goods at the following address:
          </p>
          <p>
            Products purchased on Rosmallonline should always be returned
            directly to the company the previously owned the item. Rosmallonline
            is third party offering advertisement services. Therefore,
            Rosmallonline can not under any circumstance accept return of
            already delivered good from a customer at our premises. To return a
            product bought on our site, go to shopping history under your
            account to initiate the delivery process
          </p>
          <p>
            We cannot be held responsible for Goods damaged or lost in return
            shipment. Therefore, We recommend an insured and trackable mail
            service. We are unable to issue a refund without actual receipt of
            the Goods or proof of received return delivery.
          </p>

          <h4 className="h4 py-2 text-bold">Gifts</h4>
          <hr />
          <p>
            If the Goods were marked as a gift when purchased and then shipped
            directly to you, You'll receive a gift credit for the value of your
            return. Once the returned product is received, a gift certificate
            will be mailed to You.
          </p>
          <p>
            If the Goods weren't marked as a gift when purchased, or the gift
            giver had the Order shipped to themselves to give it to You later,
            We will send the refund to the gift giver.
          </p>
          <br />

          <h5 className="h4 text-bold pt-2">Contact Us</h5>
          <hr />
          <p>
            If you have any questions about our Returns and Refunds Policy,
            please contact us:
          </p>
          <ul>
            <li className="pl-3">
              By visiting this page on our website:{" "}
              <a
                href="www.rosmallonline.com"
                rel="external nofollow noopener"
                target="_blank">
                www.rosmallonline.com
              </a>
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
