import React from "react";
import "./Scroll.css";

const Scroll = () => {
  return (
    <>
      <div className="main py-3">
        <>
          <div className="container-fluid">
            <div className="media-scroller snaps-inline bg-light">
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
              <div className="media-element">
                <img src="https://picsum.photos/200/200" alt="" />
                {/* <p className="title">short title</p> */}
              </div>
            </div>
          </div>
        </>
      </div>
    </>
  );
};

export default Scroll;
