import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import Imago from "../img/Image.module.css";

const CompanyItems = (props) => {
  console.log(props);
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [details, setDetails] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
    setId(props.data.company_uuid);
    console.log(token);

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/shopper/list_company_post/`,
        {
          company_id: id,
        },
        {
          headers: {
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            //   'X-SHOPPER-USER-AUTH': token
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        setDetails(res.data.active_post);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id, props.data.company_uuid, token]);

  return (
    <div>
      {details && (
        <h2 className="h5 text-uppercase mb-4 p-4 ">
          Other Services by {props.data.company_name}
        </h2>
      )}
      <div className="row">
        {/* PRODUCT*/}
        {details ? (
          details.map((detail) => {
            return (
              <div
                className="col-lg-2 col-md-3 col-6 p-1"
                key={detail.product_uuid}>
                <div className="product text-center skel-loader">
                  <div className="d-block mb-3 position-relative">
                    <Link
                      className="d-block"
                      to={{
                        pathname: `/ProductDetails`,
                        state: { data: detail.product_uuid },
                      }}>
                      <img
                        className={Imago.imago}
                        src={detail.image_url}
                        alt="..."
                      />
                    </Link>
                    <div className="product-overlay"></div>
                  </div>
                  <h6>
                    {" "}
                    <Link
                      className="reset-anchor"
                      to={{
                        pathname: `/ProductDetails`,
                        state: { data: detail.product_uuid },
                      }}>
                      {detail.product_name}
                    </Link>
                  </h6>
                  {detail.discount_amount === null ? (
                    <p className="ml-auto" style={{ color: "#ff0000" }}>
                      GH¢ {detail.price}
                    </p>
                  ) : (
                    <p className="ml-auto" style={{ color: "#ff0000" }}>
                      GH¢ {detail.discount_amount.substring(0, 6)}
                    </p>
                  )}
                </div>
              </div>
            );
          })
        ) : (
          <div className="">
            <p className="text-center">No Records Found</p>
          </div>
        )}
      </div>
    </div>
  );
};

export default CompanyItems;
