import axios from "axios";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const AdvanceSearch = () => {
  const [list, setList] = useState([]);
  const [name, setName] = useState("");
  const [record, setRecord] = useState("");
  const [token, setToken] = useState(null);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);
  }, [name, record, token]);

  const handleSubmit = (e) => {
    e.preventDefault();

    axios
      .post(
        `${process.env.REACT_APP_Base_url}/product/advance_search/`,
        {
          product_name: name,
          record_name: record,
          offset: "0",
          record_number: "50",
        },
        {
          headers: {
            // "X-SHOPPER-USER-AUTH": token,
            "x-user-pass-auth":
              "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
        setList(res.data.products);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Header />

      <div className="container">
        <div className="container py-3">
          <form onSubmit={handleSubmit}>
            <div className="form-row">
              <div className="form-group col-md-7">
                <label>Search by name</label>
                <input
                  type="text"
                  className="form-control  mb-3"
                  placeholder="Any item name"
                  onChange={(e) => setName(e.target.value)}
                />
              </div>
              <div className="form-group col-md-4">
                <label> Select a Category </label>
                <select
                  className="form-control mb-3"
                  id="records"
                  style={{ width: "100% !important" }}
                  onChange={(e) => setRecord(e.target.value)}>
                  <option> -- </option>
                  <option value="product">Product</option>
                  {/* <option value="service">Service</option> */}
                </select>
              </div>
            </div>

            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </form>
        </div>
        <br />
        <div className="container py-2">
          {list.map((items) => {
            return (
              <div
                className="col-lg-3 col-md-4 col-sm-6"
                key={items.product_uuid}>
                <div className="">
                  <Link
                    to={{
                      pathname: "/ProductDetails",
                      state: { data: items.product_uuid },
                    }}>
                    <img
                      src={items.image_url}
                      alt="Image1"
                      style={{ maxWidth: "100%" }}
                    />
                  </Link>
                  <h6 className="mb-0">
                    <Link
                      className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                      to={{
                        pathname: `/ProductDetails`,
                        state: { data: items.product_uuid },
                      }}>
                      {items.product_name.length < 25
                        ? `${items.product_name}`
                        : `${items.product_name.substring(0, 23)}...`}
                    </Link>
                  </h6>
                  <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                    <span className="px-1">
                      {" "}
                      {items.discount_amount !== null
                        ? `GH₵(${items.discount_amount})`
                        : `${""}`}
                    </span>
                    <del className="px-1" style={{ color: "red" }}>
                      GH₵ {items.price}
                    </del>
                    <span className="px-1">
                      {" "}
                      {items.discount === ""
                        ? `${""}`
                        : `(${items.discount}%)`}{" "}
                    </span>
                  </p>
                  <Link
                    to={{
                      pathname: `/Company`,
                      state: { data: items.company.company_uuid },
                    }}
                    className="text-decoration-none px-1 d-flex justify-content-start">
                    {items.company.company_name.length < 20
                      ? `${items.company.company_name}`
                      : `${items.company.company_name.substring(0, 19)}..`}
                  </Link>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default AdvanceSearch;
