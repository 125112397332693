import React,{useEffect, useState} from 'react';
import Footer from '../Views/Footer';
import Header from '../Views/Header';

const ReturnPolicy = () => {

  const [token, setToken] = useState(null)

  useEffect(() => {

      const tk = sessionStorage.getItem('token');
      setToken(tk);

  },[token])

  return <div>
    <Header />
    <div className="container page-holder py-5">
      <p>
          The ability to returned a product/s depending on the return policy of the company that sold it. When the shopper is shopping on RosMall, the platform allows the shopper to know items that are returnable and those which are not. Terms and conditions for items to be returned are stated clearly. When the items meet the returnable terms and condition, the shopper can return the items.. The delivery cost would be accrued to the seller. 
          Items with the tag unreturnable, cannot be return after receiving the items. 
      </p>

        <p>
          <span className="px-5">Step 1 - </span> Log into your account if you are not already login
        </p>

        <p>
          <span className="px-5">Step 2 - </span>Navigate to the shopping history and locate the item you want to return
        </p>

        <p>
          <span className="px-5">Step 3 - </span>  Click return button to initiate the process.
        </p>

        <p>
          <span className="px-5">Step 4 - </span> You will see the seller's contact details, conditions for returning the specific item and most importantly, if the item in question is returnable or not.
        </p>

        <p>
          <span className="px-5">For example : </span>       </p>

        <div className="container px-5">
                      <table className="table table-striped">
                  <thead>
                  <tr>
                      <th scope="col">#</th>
                      <th scope="col">Detail</th>
                      <th scope="col">Must be return in …</th>
                      <th scope="col">No physical defects</th>
                      <th scope="col">Sealed</th>
                      <th scope="col">Unused</th>
                      <th scope="col">Return the Complete package of delivery</th>
                  </tr>
                  </thead>
                  <tbody>
                          <tr>
                              <th scope="row">1</th>
                              <td>Change of Mind</td>
                              <td>Days or week</td>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                          <tr>
                              <th scope="row">2</th>
                              <td>Wrong Delivery</td>
                              <td>Yes</td>
                              <td>Yes</td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                          <tr>
                              <th scope="row">3</th>
                              <td>Parts missing</td>
                              <td>Yes</td>
                              <td>Yes</td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                                                  
                          <tr>
                              <th scope="row">4</th>
                              <td>Product name mis-match</td>
                              <td>Yes</td>
                              <td>Yes</td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                                                  
                          <tr>
                              <th scope="row">5</th>
                              <td>Product size mis-match</td>
                              <td>Yes</td>
                              <td>Yes</td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                                                  
                          <tr>
                              <th scope="row">6</th>
                              <td>Product type mis-match</td>
                              <td>Yes</td>
                              <td>Yes</td>
                              <td></td>
                              <td></td>
                              <td></td>
                          </tr>
                                                  
                          <tr>
                              <th scope="row">7</th>
                                  <td>Broken or damage</td>
                                  <td>Yes</td>
                                  <td>Yes</td>
                                  <td></td>
                                  <td></td>
                                  <td></td>
                                                      
                          </tr>
                                                  
                                                  
                          </tbody>
              </table>

        </div><br />

        <p>
          <span className="px-5">Step 5 - </span> Add your comment and submit it. A represent from RosmallOnline will get in touch with you to coordinate the return process
        </p><br />

        {(!token)&& 
          <p className="px-5 m-auto">
          <a className="link-item" href="/Login">Login here</a>
        </p>
        }


    </div>
    <Footer />
  </div>;
};

export default ReturnPolicy;
