import React, {  useState, useEffect }from 'react';
import axios from 'axios';
import { FaCartArrowDown ,FaUserAlt } from 'react-icons/fa'
import Hero from "../Views/Header.module.css";





const ProfileHeader = () => {

    const [token, setToken] = useState(null);
    const [cart, setCart] = useState([]);

    const endSession = () => {
      // sessionStorage.removeItem('token');
  
      // Remove all saved data from sessionStorage
      sessionStorage.clear();
    }
  
  
    //  Get shopper details
    useEffect(() => {
      const tk = sessionStorage.getItem('token');
      setToken(tk);
  
      axios
        .get("https://rosmallonline.com:8000/ecoms-api/cart/shopper_cart/", {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        })
        .then((response) => {
          console.log(response.data);
          setCart(response.data.shopper_cart_data);
        })
        .catch((error) => {
          console.log(error);
        });
     
  
    },[token])


    return (
      <div>
        <header className="header">
          {/* Top Bar */}
          <div className="top_bar">
            <div className="container">
              <div className="row">
                <div className="col d-flex flex-row">
                  <div className="top_bar_contact_item">
                    <div>
                      <a href="/">Home</a>
                    </div>
                  </div>
                  <div className="top_bar_contact_item">
                    <div />
                  </div>
                  <div className="top_bar_contact_item pl-3">
                    <div />
                  </div>
                  <div className="top_bar_contact_item">
                    <div className="top_bar_icon" />
                  </div>
                  <div className="top_bar_content ml-auto">
                    <div className="top_bar_menu">
                      <ul className="standard_dropdown top_bar_dropdown"></ul>
                    </div>

                    {!token ? (
                      <div className="top_bar_user">
                        <div className="user_icon"></div>
                        <div>
                          <a href="/Register">Register</a>
                        </div>
                        <div>
                          <a href="/Login">Sign in</a>
                        </div>
                      </div>
                    ) : (
                      <div className="top_bar_user">
                        <div>
                          <a href="/" onClick={endSession}>
                            Logout
                          </a>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* Header Main */}
          <div className="header_main">
            <div className="container">
              <div className="row">
                {/* Logo */}
                <div className="col-lg-4 col-sm-3 col-3 order-1">
                  <div className="logo_container">
                    <div className="logo">
                      <a href="/">RosmallOnline</a>
                    </div>
                  </div>
                </div>{" "}
                {/* Search */}
                <div className="col-lg-6 col-12 order-lg-2 order-3 text-lg-left text-right">
                  <div className="header_search">
                    <div className="header_search_content">
                      <div className="header_search_form_container">
                        <form
                          action="#"
                          className="header_search_form clearfix">
                          {" "}
                          <input
                            type="search"
                            required="required"
                            className="header_search_input"
                            placeholder="Search for products..."
                          />
                          <div
                            className="custom_dropdown"
                            style={{ display: "block" }}>
                            <div className="custom_dropdown_list">
                              {" "}
                              <span className="custom_dropdown_placeholder clc">
                                All Categories
                              </span>{" "}
                              <i className="fas fa-chevron-down" />
                              <ul className="custom_list clc" id="gat">
                                <li>
                                  <a className="clc" href="#/">
                                    Products
                                  </a>
                                </li>
                                <li>
                                  <a className="clc" href="#/">
                                    Services
                                  </a>
                                </li>
                              </ul>
                            </div>
                          </div>{" "}
                          <button
                            type="submit"
                            className="header_search_button trans_300"
                            value="Submit">
                            <img
                              src="https://res.cloudinary.com/dxfq3iotg/image/upload/v1560918770/search.png"
                              alt=""
                            />
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>{" "}
                {/* Wishlist */}
                <div className="col-lg-2 col-9 order-lg-3 order-2 text-lg-left text-right">
                  <div className="wishlist_cart d-flex flex-row align-items-center justify-content-end">
                    <div className="cart">
                      <div className="cart_container d-flex flex-row align-items-center justify-content-end">
                        <div className="cart_icon">
                          <a href="/ProfileCart" style={{ color: "#0e8ce4" }}>
                            {" "}
                            <FaCartArrowDown size={42} className="px-1" />{" "}
                          </a>
                          {cart && (
                            <div className="cart_count">
                              <span>{cart.length}</span>
                            </div>
                          )}
                        </div>
                        <div className="cart_content pl-2">
                          <div className="cart_icon">
                            <a href="/Profile" style={{ color: "#0e8ce4" }}>
                              {" "}
                              <FaUserAlt size={42} className="px-1" />{" "}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>{" "}
          {/* Main Navigation */}
          <nav
            className="navbar navbar-expand-lg navbar-light p-2"
            id={Hero.bb}>
            <section className="container">
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon" id="profile_header"/>
              </button>
              <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav">
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/Profile"
                      style={{ opacity: "1.8", color: "white" }}>
                      Profile
                    </a>
                  </li>
                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ProfileCart"
                      style={{ opacity: "1.8", color: "white" }}>
                      Cart
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/ShoppingHistory"
                      style={{ opacity: "1.8", color: "white" }}>
                      Shopping History
                    </a>
                  </li>

                  <li className="nav-item">
                    <a
                      className="nav-link"
                      href="/UpdateProfile"
                      style={{ opacity: "1.8", color: "white" }}>
                      Settings
                    </a>
                  </li>
                </ul>
              </div>
            </section>
          </nav>
        </header>
      </div>
    );
}

export default ProfileHeader
