import React from "react";

const Footer = () => {
  let date = new Date().getFullYear();

  return (
    <footer className="bg-dark text-white">
      <div className="container py-4">
        <div className="row py-5">
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="text-uppercase mb-3">Customer services</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a className="footer-link" href="tel:0302971344">
                  For Help, Contact Us
                </a>
              </li>
              <li>
                <a className="footer-link" href="/Refund">
                  Returns &amp; Refunds
                </a>
              </li>
              <li>
                <a className="footer-link" href="/Privacy">
                  Privacy
                </a>
              </li>
              <li>
                <a className="footer-link" href="/TermsAndCondition">
                  Terms &amp; Conditions
                </a>
              </li>
            </ul>
          </div>
          <div className="col-md-4 mb-3 mb-md-0">
            <h6 className="text-uppercase mb-3">Company</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a className="footer-link" href="#/">
                  What We Do
                </a>
              </li>
              <li>
                <a className="footer-link" href="/sell">
                  Available Services
                </a>
              </li>
              {/* <li>
                <a className="footer-link" href="#/">
                  Latest Posts
                </a>
              </li> */}
              {/* <li><a className="footer-link" href="#/">FAQs</a></li> */}
            </ul>
          </div>
          <div className="col-md-4">
            <h6 className="text-uppercase mb-3">Social media</h6>
            <ul className="list-unstyled mb-0">
              <li>
                <a
                  className="footer-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://twitter.com/rosmall_online">
                  <img
                    src="https://img.icons8.com/color/38/000000/twitter--v1.png"
                    alt="Twitter"
                  />
                </a>

                <a
                  className="footer-link"
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.instagram.com/ros_mall/">
                  <img
                    src="https://img.icons8.com/color/38/000000/instagram-new--v2.png"
                    alt="Instagram"
                  />
                </a>

                <a className="footer-link" href="#/">
                  <img
                    src="https://img.icons8.com/color/38/000000/facebook-new.png"
                    alt="facebook"
                  />
                </a>

                <a className="footer-link" href="#/">
                  <img
                    src="https://img.icons8.com/color/38/000000/youtube-play.png"
                    alt="Youtube"
                  />
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div
          className="border-top pt-4"
          style={{ borderColor: "#1d1d1d !important" }}>
          <div className="row">
            <div className="col-lg-6">
              <p className="small text-muted mb-0">
                © {date} All rights reserved.
              </p>
            </div>
            <div className="col-lg-6 text-lg-right">
              <p className="small text-muted mb-0">
                Designed by{" "}
                <a
                  className="text-white reset-anchor"
                  href="https://Roscareer.com">
                  Roscareer
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
