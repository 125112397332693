import React, { useEffect } from "react";
import { useLocation, useHistory } from "react-router";
import axios from "axios";
import queryString from "query-string";
import Footer from "../Views/Footer";
import Header from "../Views/Header";

const ActivationPage = () => {
  const history = useHistory();
  const { search } = useLocation();
  const { email, profile_id } = queryString.parse(search);
  console.log(search);

  useEffect(() => {
    if (email && profile_id) {
      axios
        .post(
          `${process.env.REACT_APP_Base_url}/shopper/activate_shopper_account/`,
          {
            acct_uuid: profile_id,
            email,
          }
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            console.log(" Redirect successful ");
            alert(res.data.message);
            history.push("/Login");
          } else {
            console.log("Redirect failed");
            alert(res.data.message);
            // history.push('/register')
          }
        });
    }
  }, [email, profile_id, history]);

  return (
    <div className="page-holder">
      <Header />

      <div className="container py-5" style={{ height: "60vh" }}>
        <h1 className="text-center">Activation Page</h1>
        <hr />
        <p className="text-center py-3">
          Your account with email address <b>{email}</b> has been successfully
          activated.
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default ActivationPage;
