import React from 'react'
import Ads from '../img/new rosmall.png'
import Ad from '../img/new rosmall 1.png'
import B from '../img/banner 3.png'
import C from "../img/banner cart.png";
import D from "../img/banner sign up.png";

import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import { Autoplay, Pagination, Navigation } from "swiper";

const SliddersDashboard = () => {
  return (
    <div className="container">
      <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        autoplay={{
          delay: 4500,
          disableOnInteraction: false,
        }}
        pagination={{
          clickable: true,
        }}
        modules={[Autoplay, Pagination, Navigation]}
        className="mySwiper">
        <SwiperSlide>
          <a href="https://www.roscareer.com/">
            <img className="" src={Ad} alt=".." width="100%" />
          </a>
        </SwiperSlide>
        <SwiperSlide>
          <img className="" src={D} alt=".." width="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="" src={B} alt=".." width="100%" />
        </SwiperSlide>
        <SwiperSlide>
          <img className="" src={Ads} alt=".." width="100%" />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default SliddersDashboard
