import React, {  useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../Views/Footer';
import axios from 'axios';
import Header from '../Views/Header';
import CompanyFilter from '../Filter/CompanyFilter';
import Imago from '../img/Image.module.css'
import No_Logo from '../img/no_logo.jpeg';
import ReactPagination from "react-paginate";






 const Companies = () => {

  const [companies , setCompanies] = useState([]);
  const [token, setToken] = useState(null)
  const [industries, setIndustries] = useState([])
  const [industryList, setIndustryList]=useState(null);
  const [pageNumber,setPageNumber] = useState(0)


  //  Get all companies list
  useEffect(() => {
    const tk = sessionStorage.getItem('token');
    setToken(tk)
    axios.get(`${process.env.REACT_APP_Base_url}/shopper/list_companies/`,
      {
        headers: {
          // 'X-SHOPPER-USER-AUTH': token
          'x-user-pass-auth': "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token"
       }
    })
      .then((res) => {
      console.log(res.data);
      setCompanies(res.data.companies);
    }).catch((err) => {
      console.log(err);
    });

    axios.get(`${process.env.REACT_APP_Base_url}/setup/get_industries/`)
    .then((res2) => {
      console.log(res2.data);
      setIndustryList(res2.data.industries);
    })
    .catch(console.log)

  },[token])


  const usersPerPage = 16
  const pagesVisited = pageNumber * usersPerPage
  const pageCount = companies ? (Math.ceil(companies.length / usersPerPage)) : 0

  const changePage = ({selected}) => {
      setPageNumber(selected)
  }




        return (
            <div className="page-holder">

                <Header />
                
            {/* search bar for small screens */}
              <div className="input-group flex-nowrap py-2 px-3" id="sb">
                <a href="/AdvanceSearch" type="button" className="btn btn-primary form-control text-white" role="button" style={{backgroundColor: '#0e8ce4'}}>Advance Search</a>
              </div>
            {/*End of search bar for small screens */}
            {/*  Modal */}
            <div className="container">
              <section className="py-5">
                <div className="container p-0">
                  {/* SHOP LISTING*/}
                  <div className="col-lg-12 order-1 order-lg-2 mb-5 mb-lg-0">
                    <div className="row mb-3 align-items-center" id="sort">
                      <div className="col-lg-6 mb-2 mb-lg-0">
                        {companies &&
                          <p className="text-small text-muted mb-0">Showing {companies.length} results</p>
                        }
                      </div>
                      <div className="col-lg-6">
                        <ul className="list-inline d-flex align-items-center justify-content-lg-end mb-0">
                          <li className="list-inline-item text-muted mr-3"><Link className="reset-anchor p-0" to="#/"><i className="fas fa-th-large" /></Link></li>
                          <li className="list-inline-item text-muted mr-3"><Link className="reset-anchor p-0" to="#/"><i className="fas fa-th" /></Link></li>
                         <CompanyFilter />
                        
                        </ul>
                      </div>
                    </div>
                    {/*COMPANIES*/}
                    
                    <div className="row pb-3 text-center">
                     {companies &&
                      companies.map((item) => (
                        <div className="col-lg-2 col-md-4 col-6 mt-2" key={item.company_uuid}>
                          <div className="">
                            <Link to={{ pathname: `/Company` , state: {data :item.company_uuid } }}  className="">
                              {/* <img className={Imago.comp} src="https://picsum.photos/200/150/?random" alt="company logo" /> */}
                              {(item.company_logo === "No Logo" ) ? 
                                <img className={Imago.comp} src={No_Logo} alt="company logo" /> 
                              :
                                <img className={Imago.comp} src={item.company_logo} alt="company logo" /> 
                              }
                            </Link>
                            <div className="py-3">
                              <small className="card-title">
                                {`${item.company_name.substring(0,12)}..` }
                                {/* {item.company_name.length < 15 ? `${item.company_name}` : `${item.company_name.substring(0,8)}..` } */}
                              </small>
                              <p>Industry - {item.industry.industry_name.length < 13 ? `${item.industry.industry_name}` : `${item.industry.industry_name.substring(0,8)}...` }</p>
                            </div>
                          </div>
                        </div>
                      ))
                      }
                     
                    </div>
                    {/* END OF COMPANIES */}
                    
                   
                    {/* PAGINATION*/}
                    {(companies) &&
                             <div className="container">
                             <ReactPagination 
                               previousLabel={"<<"}
                               nextLabel={">>"}
                               pageCount={pageCount}
                               onPageChange={changePage}
                               breakLabel={'...'}
                               breakClassName={"page-item"}
                               breakLinkClassName={'page-link'}
                               containerClassName={"pagination justify-content-end"}
                               pageClassName={'page-item'}
                               pageLinkClassName={'page-link'}
                               previousClassName={'page-item'}
                               previousLinkClassName={'page-link'}
                               nextClassName={'page-item'}
                               nextLinkClassName={'page-link'}
                               activeClassName={'active'}
                             />
                           </div>
                          }
                    
                  </div>
                </div>
              </section>
            </div>
            
                <Footer />
         
      </div>
    
        );
    }

export default Companies;
