import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Footer from "../Views/Footer";
import ProfileHeader from "./ProfileHeader";
import axios from "axios";
import { TiDelete } from "react-icons/ti";

const ProfileCart = () => {
  const history = useHistory();
  const [token, setToken] = useState(null);
  const [cartList, setCartList] = useState([]);
  // const [id, setId] = useState(null)

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get("https://rosmallonline.com:8000/ecoms-api/cart/shopper_cart/", {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((res) => {
        console.log(res.data);
        setCartList(res.data.shopper_cart_data);
        if (res.data.status === 400) {
          alert(res.data.message);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [token]);

  const deleteCart = async (id) => {
    const ko = sessionStorage.getItem("token");

    await axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/cart/delete_cart_item/",
        {
          cart_ids: [id],
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": ko,
          },
        }
      )
      .then((resp) => {
        console.log(resp.data);
        if (resp.data.status === 200) {
          alert(resp.data.message);
          history.push("/ProfileCart");
          window.location.reload(true);
        } else {
          console.log("Redirect failed");
          // history.push('/register')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div className="page-holder">
      <ProfileHeader />
      {/* End of Header */}
      {/* CATEGORIES SECTION*/}
      <section className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-4 mb-lg-0">
            {/* CART TABLE*/}
            <div className="table-responsive mb-4">
              <table className="table">
                <thead className="bg-light">
                  <tr>
                    <th className="border-0" scope="col">
                      {" "}
                      <strong className="text-small text-uppercase">
                        Product
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      {" "}
                      <strong className="text-small text-uppercase">
                        Quantity
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      {" "}
                      <strong className="text-small text-uppercase">
                        Total
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      {" "}
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {cartList &&
                    cartList.map((cart) => {
                      return (
                        <tr key={cart.cart_uuid}>
                          <th className="pl-0 border-0" scope="row">
                            <div className="media align-items-center">
                              <a
                                className="reset-anchor d-block animsition-link"
                                href="/">
                                <img
                                  src={cart.product.image_url}
                                  alt="..."
                                  width={70}
                                />
                              </a>
                              <div className="media-body ml-3">
                                <strong className="h6">
                                  <Link
                                    className="reset-anchor animsition-link"
                                    to={{
                                      pathname: `/CartDetails/${cart.product.product_name}`,
                                      state: { data: cart.cart_uuid },
                                    }}>
                                    {" "}
                                    {cart.product.product_name}{" "}
                                  </Link>
                                </strong>
                              </div>
                            </div>
                          </th>

                          <td className="align-middle border-0">
                            <div className="quantity">
                              <p className="form-control form-control-sm border-0 shadow-0 p-3">
                                {" "}
                                {cart.quantity}{" "}
                              </p>
                            </div>
                          </td>
                          <td className="align-middle border-0">
                            <p className="mb-0 small">GH₵ {cart.total}</p>
                          </td>
                          <td className="align-middle border-0">
                            <span
                              className="reset-anchor"
                              onClick={() => deleteCart(cart.cart_uuid)}>
                              <TiDelete size={24} color="red" />
                            </span>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
            {/* CART NAV*/}
            <div className="bg-light px-4 py-3">
              <div className="row align-items-center text-center">
                <div className="col-md-6 mb-3 mb-md-0 text-md-left">
                  <a className="btn btn-link p-0 text-dark btn-sm" href="/">
                    <i className="fas fa-long-arrow-alt-left mr-2"> </i>Continue
                    shopping
                  </a>
                </div>
                <div className="col-md-6 text-md-right">
                  <Link
                    className="btn btn-outline-dark btn-sm"
                    to={{ pathname: "/Checkout", state: { data: cartList } }}>
                    Proceed to checkout
                    <i className="fas fa-long-arrow-alt-right ml-2" />
                  </Link>
                </div>
              </div>
            </div>
          </div>
          {/* ORDER TOTAL*/}
        </div>
      </section>
      {/*END OF CATEGORIES SECTION*/}

      <Footer />
    </div>
  );
};

export default ProfileCart;
