import axios from "axios";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";

const Login = () => {
  const history = useHistory();
  const [data, setData] = useState({ email: "", password: "" });

  const submitHandler = async (e) => {
    e.preventDefault();

    if (data.email !== "" && data.password !== "") {
      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/shopper/shopper_login/",
          data
        )
        .then((res) => {
          console.log(res);
          if (res.data.status === 200) {
            sessionStorage.setItem("token", res.data.token);
            console.log("Redirect successful");
            // alert(res.data.message);
            history.push("/");
          } else {
            console.log("Redirect failed");
            alert(res.data.message);
            // history.push('/register')
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };

  return (
    <div style={{ height: "100vh", paddingTop: "100px" }}>
      <div className="container py-5 pt-5">
        <div className="container">
          <div className="container">
            <div className="row py-5 ">
              <section className="col-sm-12">
                <div className="card">
                  <section className="card-body">
                    <a
                      className="float-right btn btn-outline-primary"
                      href="/Register"
                      style={{ backgroundColor: "#0e8ce4", color: "white" }}>
                      Register Here
                    </a>
                    <h4 className="card-title mb-4 mt-1">Sign in</h4>
                    <br />

                    {/* <p>
                              <a href="#/" className="btn btn-block btn-outline-info"> <i className="fab fa-google" /> &nbsp; Login via Google</a>
                              <a href="#/" className="btn btn-block btn-outline-primary"> <i className="fab fa-facebook-f" /> &nbsp; Login via facebook</a>
                            </p> */}
                    <hr />

                    <form onSubmit={submitHandler}>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-user" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="Email"
                            type="email"
                            name="email"
                            onChange={(e) =>
                              setData({ ...data, email: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <div className="input-group">
                          <div className="input-group-prepend">
                            <span className="input-group-text">
                              <i className="fa fa-lock" />
                            </span>
                          </div>
                          <input
                            className="form-control"
                            placeholder="******"
                            type="password"
                            name="password"
                            onChange={(e) =>
                              setData({ ...data, password: e.target.value })
                            }
                          />
                        </div>
                      </div>
                      <div className="form-group">
                        <button
                          type="submit"
                          className="btn btn-primary btn-block"
                          style={{
                            backgroundColor: "#0e8ce4",
                            color: "white",
                          }}>
                          Login
                        </button>
                      </div>
                      <p className="text-center">
                        <a
                          href="/ForgotPassword"
                          className="btn m-1"
                          style={{ borderColor: "#0e8ce4", color: "#000" }}>
                          Forgot password ?
                        </a>
                        <a
                          href="https://companies.rosjob.tech/"
                          target="_blank"
                          rel="noreferrer"
                          className="btn m-1"
                          style={{ borderColor: "#0e8ce4", color: "#000" }}>
                          Login as a company
                        </a>
                      </p>
                    </form>
                  </section>
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
