import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Footer from "../../Views/Footer";
import Header from "../../Views/Header";
import axios from "axios";
import ProductFilter from "../../Filter/ProductFilter";
import Imago from "../../img/Image.module.css";
import ReactPagination from "react-paginate";

const Products = (props) => {
  const [electronics, setElectronics] = useState([]);
  const [subCat, setSubCat] = useState([]);
  const [id, setId] = useState("");
  const [pageNumber, setPageNumber] = useState(0);

  console.log(props);

  useEffect(() => {
    if (props.location.state) {
      setId(props.location.state.data);
    }
  }, [props.location.state]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/setup/list_product_sub_category/",
          {
            product_cat_uuid: id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
              // 'X-SHOPPER-USER-AUTH': tk
            },
          }
        )
        .then((resp) => {
          console.log(resp.data);
          setSubCat(resp.data.product_sub_category);
        })
        .catch((err) => {
          console.log(err);
        });

      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/product/get_product_posts/",
          {
            product_cat_id: id,
          },
          {
            headers: {
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          if (res.data.message === "Record(s) Not Found") {
            setElectronics([]);
            alert("No Product(s) Found");
          } else {
            console.log(res.data);
            setElectronics(res.data.product_posts);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id]);

  const usersPerPage = 16;
  const pagesVisited = pageNumber * usersPerPage;
  const pageCount = electronics
    ? Math.ceil(electronics.length / usersPerPage)
    : 0;

  const changePage = ({ selected }) => {
    setPageNumber(selected);
  };

  return (
    <div className="page-holder">
      <Header />

      {/* search bar for small screens */}
      <div className="input-group flex-nowrap py-2 px-3" id="sb">
        <a
          href="/AdvanceSearch"
          type="button"
          className="btn btn-primary form-control text-white"
          role="button"
          style={{ backgroundColor: "#0e8ce4" }}>
          Advance Search
        </a>
      </div>
      {/*End of search bar for small screens */}

      <div className="container">
        <section className="py-5">
          <div className="container p-0">
            <div className="row">
              {/* SHOP SIDEBAR*/}
              <div className="col-lg-2 order-2 order-lg-1">
                <h5 className="text-uppercase mb-4">Categories</h5>
                <div className="py-2 px-4 bg-dark text-white mb-3">
                  <strong className="small text-uppercase font-weight-bold">
                    Products
                  </strong>
                </div>
                <ul className="list-unstyled small text-muted font-weight-normal bg-light">
                  {subCat &&
                    subCat.map((item) => {
                      return (
                        <li className="mb-0" key={item.product_sub_category_id}>
                          <Link
                            className="dropdown-item"
                            to={{
                              pathname: `/product_/${item.sub_category_name}`,
                              state: { data: item.product_sub_category_id },
                            }}>
                            {item.sub_category_name}
                          </Link>
                        </li>
                      );
                    })}
                </ul>

                <div className="price-range pt-4 mb-5">
                  <ProductFilter />
                </div>
              </div>
              {/* SHOP LISTING*/}
              <div className="col-lg-9 order-1 order-lg-2 mb-5 mb-lg-0">
                <div className="row mb-3 align-items-center" id="sort">
                  <div className="col-lg-6 mb-2 mb-lg-0">
                    {electronics && (
                      <p className="text-small text-muted mb-0">
                        Total items: {electronics.length}
                      </p>
                    )}
                  </div>
                  <div className="col-lg-6">
                    <ul className="list-inline d-flex align-items-center justify-content-lg-end mb-0">
                      <li className="list-inline-item text-muted mr-3">
                        <Link className="reset-anchor p-0" to="#">
                          <i className="fas fa-th-large" />
                        </Link>
                      </li>
                      <li className="list-inline-item text-muted mr-3">
                        <Link className="reset-anchor p-0" to="#">
                          <i className="fas fa-th" />
                        </Link>
                      </li>
                      <li className="list-inline-item">
                        <select
                          className="selectpicker ml-auto"
                          name="sorting"
                          data-width={200}
                          data-style="bs-select-form-control"
                          data-title="Default sorting">
                          <option value="default">Default sorting</option>
                          <option value="Cameras">Cameras</option>
                          <option value="Photos">Photos</option>
                          <option value="Phones">Phones</option>
                          <option value="Watches">Watches</option>
                          <option value="computers">computers</option>
                          <option value="tablets">tablets</option>
                          <option value="Headphones">Headphones</option>
                          <option value="Surveillance">Surveillance</option>
                          <option value="TV">TV</option>
                          <option value="Speaker and Audio">
                            Speaker and Audio
                          </option>
                          <option value="Games">Games</option>
                          <option value="Ink /toner">Ink /toner</option>
                          <option value="Security Accessories">
                            Security Accessories
                          </option>
                          <option value="Softwares">Softwares</option>
                          <option value="Drones">Drones</option>
                          <option value="Network">Network</option>
                        </select>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="row">
                  {electronics &&
                    electronics
                      .slice(pagesVisited, pagesVisited + usersPerPage)
                      .map((electronic) => (
                        <div
                          className="col-lg-3 col-md-3 col-6 p-1"
                          key={electronic.product_uuid}>
                          <div className="product">
                            <div className="mb-3 position-relative">
                              <div className="badge text-white" />
                              <Link
                                className="d-block"
                                to={{
                                  pathname: `/ProductDetails`,
                                  state: { data: electronic.product_uuid },
                                }}>
                                <img
                                  className={Imago.imago}
                                  src={electronic.image_url}
                                  alt="..."
                                />
                              </Link>
                              <div className="product-overlay"></div>
                            </div>
                            <h6 className="mb-0">
                              <Link
                                className="reset-anchor px-1 mb-0 d-flex justify-content-start"
                                to={{
                                  pathname: `/ProductDetails`,
                                  state: { data: electronic.product_uuid },
                                }}>
                                {electronic.product_name.length < 25
                                  ? `${electronic.product_name}`
                                  : `${electronic.product_name.substring(
                                      0,
                                      23
                                    )}...`}
                              </Link>
                            </h6>
                            <p className="small font-weight-bolder mb-0 d-flex justify-content-start">
                              <span className="px-1">
                                {electronic.discount_amount !== null
                                  ? `GH₵(${electronic.discount_amount.substring(
                                      0,
                                      6
                                    )})`
                                  : null}
                              </span>
                              <del className="px-1" style={{ color: "red" }}>
                                GH₵ {electronic.price}
                              </del>
                              <span className="px-1">
                                {electronic.discount === null
                                  ? null
                                  : `(${electronic.discount}%)`}
                              </span>
                            </p>
                            <Link
                              to={{
                                pathname: `/Company`,
                                state: {
                                  data: electronic.company.company_uuid,
                                },
                              }}
                              className="text-decoration-none px-1 d-flex justify-content-start">
                              {electronic.company.company_name.length < 20
                                ? `${electronic.company.company_name}`
                                : `${electronic.company.company_name.substring(
                                    0,
                                    19
                                  )}..`}
                            </Link>
                            <hr />
                          </div>
                        </div>
                      ))}
                </div>
                {electronics && (
                  <div className="container">
                    <ReactPagination
                      previousLabel={"<<"}
                      nextLabel={">>"}
                      pageCount={pageCount}
                      onPageChange={changePage}
                      breakLabel={"..."}
                      breakClassName={"page-item"}
                      breakLinkClassName={"page-link"}
                      containerClassName={"pagination justify-content-end"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextClassName={"page-item"}
                      nextLinkClassName={"page-link"}
                      activeClassName={"active"}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>
      </div>

      <Footer />
    </div>
  );
};

export default Products;
