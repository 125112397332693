import React, { Component } from 'react';
import "./Sell.css";

export default class Sell extends Component {
    render() {
        return (
            
            <div className="demo11 pt-5" style={{height: '100vh'}}>
        <div className="container">
          <h4 className="text-center py-5 h3">PRICING TABLE </h4>
          <div className="row">
            <div className="col-md-4 col-sm-6">
              <div className="pricingTable11">
                <div className="pricingTable-header">
                  <i className="fa fa-adjust" />
                  {/* <div className="price-value"> GH¢0.00 <span className="month">per month</span> </div> */}
                </div>
                <h4 className="heading">Pay as you Go (only for products)</h4>
                <div className="pricing-content">
                  <ul>
                    <li>Registration fees one time payment: <b style={{color: '#ffa442'}}>NA</b> </li>
                    <li>Advertise slot in month: <b style={{color: '#ffa442'}}>Unlimited</b></li>
                    <li>Personal Account management: <b style={{color: '#ffa442'}}>Yes</b></li>
                    <li>Account Dashboard:  <b style={{color: '#ffa442'}}>Yes</b></li>
                    <li>Pay per post:  <b style={{color: '#ffa442'}}>5% of Product sale</b></li>
                  </ul>
                </div>
                <div className="pricingTable-signup">
                  <a href="https://companies.rosjob.tech/Register">sign up</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="pricingTable11 green">
                <div className="pricingTable-header">
                  <i className="fa fa-briefcase" />
                  {/* <div className="price-value"> GH¢3.00  <span className="month">per product / services a day</span> </div> */}
                </div>
                <h3 className="heading">Business standard</h3>
                <div className="pricing-content">
                  <ul>
                    <li>Registration fees one time payment: <b style={{color: '#40c952'}}>NA</b> </li>
                    <li>Advertise slot in month :<b style={{color: '#40c952'}}>Unlimited</b> </li>
                    <li>Personal Account management: <b style={{color: '#40c952'}}>Yes</b></li>
                    <li>Account Dashboard: <b style={{color: '#40c952'}}>Yes</b> </li>
                    <li>Pay per post: <b style={{color: '#40c952'}}>Yes</b> </li>
                  </ul>
                </div>
                <div className="pricingTable-signup">
                  <a href="https://companies.rosjob.tech/Register">sign up</a>
                </div>
              </div>
            </div>
            <div className="col-md-4 col-sm-6">
              <div className="pricingTable11 red">
                <div className="pricingTable-header">
                  <i className="fa fa-cube" />
                  {/* <div className="price-value">  GH¢300.00 <span className="month">per month</span> </div> */}
                </div>
                <h3 className="heading">Business Plus</h3>
                <div className="pricing-content">
                  <ul>
                    <li>Registration fees one time payment: <b style={{color: '#ff4b4b'}}>NA</b> </li>
                    <li>Advertise slot in month: <b style={{color: '#ff4b4b'}}>5 products/services</b></li>
                    <li>Personal Account management: <b style={{color: '#ff4b4b'}}>Yes</b> </li>
                    <li>Account Dashboard: <b style={{color: '#ff4b4b'}}>Yes</b> </li>
                    <li>Pay per post: <b style={{color: '#ff4b4b'}}>NA</b> </li>
                  </ul>
                </div>
                <div className="pricingTable-signup">
                <a href="https://companies.rosjob.tech/Register">sign up</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


        )
    }
}
