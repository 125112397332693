import React, { useState, useEffect } from "react";
import axios from "axios";
import { Link } from "react-router-dom";

const ProductReviews = (props) => {
  console.log(props);
  const [token, setToken] = useState(null);
  const [id, setId] = useState(null);
  const [details, setDetails] = useState([]);

  useEffect(() => {
    if (props) {
      const tk = sessionStorage.getItem("token");
      setToken(tk);
      setId(props.product.productDetails.company.company_uuid);
    }
  }, [props, props.product.productDetails.company.company_uuid]);

  useEffect(() => {
    if (id) {
      axios
        .post(
          "https://rosmallonline.com:8000/ecoms-api/shopper/list_company_reviews/",
          {
            company_id: id,
            record_number: "",
          },
          {
            headers: {
              // "X-SHOPPER-USER-AUTH": token,
              "x-user-pass-auth":
                "AWDV+_?UOgfhjgTTEghfg-GHGBV_DDS-+567656yh76rg#@BH%&#$)(*&^%$#@@!FREE_token",
            },
          }
        )
        .then((res) => {
          console.log(res.data.reviews);
          setDetails(res.data.reviews.slice(0, 4));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [id, token]);

  return (
    <div>
      <div className="p-4 p-lg-5 bg-white">
        <div className="py-3">
          <label className="text-uppercase text-center">
            review of Company's performance
          </label>{" "}
          <hr />
        </div>
        <div className="row">
          <div className="col-lg-8">
            {details &&
              details.map((detail) => {
                return (
                  <div className="media mb-3" key={detail.review_id}>
                    <img
                      className="rounded-circle"
                      src="https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png"
                      alt=".."
                      width={50}
                    />
                    <div className="media-body ml-3">
                      <h6 className="mb-0 text-uppercase">
                        {detail.shopper.first_name}
                      </h6>
                      <p className="small text-muted mb-0 text-uppercase">
                        {detail.date_created}
                      </p>
                      <p className="small text-muted mb-0 text-uppercase">
                        {detail.rating}
                        <li className="list-inline-item m-0">
                          <i className="fas fa-star text-warning" />
                        </li>
                      </p>
                      <ul className="list-inline mb-1 text-xs">
                        {() => {
                          for (let i = 0; i < detail.rating; i++) {
                            return (
                              <li className="list-inline-item m-0">
                                <i className="fas fa-star text-warning" />
                              </li>
                            );
                          }
                        }}
                        {/* 
                                          <li className="list-inline-item m-0"><i className="fas fa-star text-warning" /></li>
                                          <li className="list-inline-item m-0"><i className="fas fa-star text-warning" /></li>
                                          <li className="list-inline-item m-0"><i className="fas fa-star text-warning" /></li>
                                          <li className="list-inline-item m-0"><i className="fas fa-star-half-alt text-warning" /></li> */}
                      </ul>
                      <p className="text-small mb-0 text-muted">
                        {detail.comment}
                      </p>
                    </div>
                  </div>
                );
              })}

            <div className="ml-auto">
              <Link
                to={{
                  pathname: `/Company`,
                  state: {
                    data: props.product.productDetails.company.company_uuid,
                  },
                }}
                className="">
                Read more ...
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductReviews;
