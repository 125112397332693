import React, { useState, useEffect } from "react";
// import { Link } from 'react-router-dom'
import Footer from "../Views/Footer";
import ProfileHeader from "./ProfileHeader";
import axios from "axios";
import { Link } from "react-router-dom";

const ShoppingHistory = () => {
  const [token, setToken] = useState(null);
  const [history, setHistory] = useState([]);

  useEffect(() => {
    const tk = sessionStorage.getItem("token");
    setToken(tk);

    axios
      .get("https://rosmallonline.com:8000/ecoms-api/cart/shopping_history/", {
        headers: {
          "X-SHOPPER-USER-AUTH": token,
        },
      })
      .then((response) => {
        console.log(response.data);
        setHistory(response.data.shopper_shopping_history);
        if (response.data.status === 400) {
          alert(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        // alert(error);
      });
  }, [token]);

  const payOut = (id) => {
    console.log(id);
    axios
      .post(
        "https://rosmallonline.com:8000/ecoms-api/payment/payout/",
        {
          cart_id: id,
        },
        {
          headers: {
            "X-SHOPPER-USER-AUTH": token,
          },
        }
      )
      .then((res) => {
        console.log(res.data);
        alert(res.data.message);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setMessage = (id) => {
    alert("Will be contacted soon");
  };

  return (
    <div className="page-holder">
      <ProfileHeader />

      {/* CATEGORIES SECTION*/}
      <section className="container py-5">
        <div className="row">
          <div className="col-lg-12 mb-4 mb-lg-0">
            {/* CART TABLE*/}
            <div className="table-responsive mb-4">
              <table className="table">
                <thead className="bg-light">
                  <tr>
                    <th className="border-0" scope="col">
                      <strong className="text-small text-uppercase">
                        Product
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      <strong className="text-small text-uppercase">
                        Review
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      <strong className="text-small text-uppercase">
                        Return
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      <strong className="text-small text-uppercase">
                        Payout
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      <strong className="text-small text-uppercase">
                        Quantity
                      </strong>
                    </th>
                    <th className="border-0" scope="col">
                      Total
                    </th>
                  </tr>
                </thead>

                <tbody>
                  {history &&
                    history.map((historyItem) => {
                      return (
                        <tr key={historyItem.cart_uuid}>
                          <th className="pl-0 border-0" scope="row">
                            <div className="media-body ml-3">
                              <strong className="h6">
                                <a
                                  className="reset-anchor animsition-link"
                                  href="#/">
                                  {historyItem.product.product_name}
                                </a>
                              </strong>
                            </div>
                          </th>
                          <td className="align-middle border-0">
                            <p className="mb-0 small">
                              <Link
                                to={{
                                  pathname: "/review",
                                  state: {
                                    id: historyItem.product.company
                                      .company_uuid,
                                  },
                                }}
                                className="btn btn-primary">
                                Review
                              </Link>
                            </p>
                          </td>
                          <td className="align-middle border-0">
                            <p className="mb-0 small">
                              <button
                                className="btn btn-primary"
                                onClick={() => setMessage()}>
                                Return
                              </button>
                              {/* <Link
                                to={{
                                  pathname: "/return",
                                  state: { data: historyItem.product },
                                }}
                                className="btn btn-primary">
                                Return
                              </Link> */}
                            </p>
                          </td>

                          <td className="align-middle border-0">
                            {historyItem.status === "PAYMENT COMPLETED" ? (
                              <p className="mb-0 small">
                                <button
                                  onClick={() => payOut(historyItem.cart_uuid)}
                                  type="button"
                                  className="btn btn-danger">
                                  Done
                                </button>
                              </p>
                            ) : (
                              <p className="mb-0 small">
                                <button
                                  onClick={() => payOut(historyItem.cart_uuid)}
                                  type="button"
                                  className="btn btn-primary">
                                  Payout
                                </button>
                              </p>
                            )}
                          </td>
                          <td className="align-middle border-0">
                            <p>{historyItem.quantity}</p>
                          </td>
                          <td className="align-middle border-0">
                            <p className="mb-0 small">
                              GH₵ {historyItem.total}
                            </p>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
      {/*END OF CATEGORIES SECTION*/}

      <Footer />
    </div>
  );
};

export default ShoppingHistory;
